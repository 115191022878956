/* eslint-disable no-unused-vars */
import ProgressBar from "@ramonak/react-progress-bar";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { useEffect, useRef, useState } from "react";
import { Icons } from "../../../shared/lib/Icon";
import Modal from "../../ul/modal/Modal";

// eslint-disable-next-line react/prop-types
function FileUploader({
  icon,
  isRequired = true,
  name,
  onChange, onRemove
}) {
  const [files, setFiles] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [isModalShow, setIsModalShow] = useState(false);
  const [convertedSizeToMb, setConvertedSizeToMb] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [progress, setProgress] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  //   here are meme types that support
  const supportMemeTypes = ["image/jpg", "image/png", "image/jpeg"];

  //   blob which is the actual data that will have to send to server
  const [getFormDataImage, setFormDataImage] = useState(null);

  const imageRef = useRef(null);
  const cropperRef = useRef(null);

  const onDropHandler = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    removeHandler();
    setUploadError("");
    const files = Array.from(ev.dataTransfer.files);

    // file name validation

    if (!supportMemeTypes.includes(files[0].type)) {
      return setUploadError("File could not be uploaded");
    }

    const mb = convertBytesToMb(files[0].size);

    setFiles(files);
    setConvertedSizeToMb(mb);
    setFileName(files[0].name);

    if (mb > 5) {
      return setUploadError("File size exceeds the limit ");
    }

   
    fileReader(files[0], (result, err) => {
      if (result) {
        setPreviewURL(result);
        modalHandler();
      }
    });

    // validations
  };

  const onDropOverHandler = (ev) => {
    ev.preventDefault();
    setUploadError("");
  };

  const fileReader = (file, callBack) => {
    const reader = new FileReader();

    if (file) {
      reader.onload = () => {
        return callBack(reader.result, null);
      };

      reader.onerror = (err) => {
        return callBack(null, err);
      };

      reader.readAsDataURL(file);
    }
  };

  //   modal handler

  const modalHandler = () => {
    setIsModalShow(!isModalShow);
  };

  const inputFileHandler = (e) => {
    setUploadError("");
    if (e?.target?.files[0]) {
      fileReader(e.target.files[0], (result, err) => {
        if (result) {
          setPreviewURL(result);
          const mb = convertBytesToMb(e?.target?.files[0].size);
          setConvertedSizeToMb(mb);
          if (mb > 5) {
            return setUploadError("File size exceeds the limit ");
          }
          setFileName(e?.target?.files[0].name);
          if (typeof onChange === "function") {
            // e.target.name , e.target.files[0]
            onChange(e);
            console.log("FileUploader: ", e.target.files[0]);
          }
          modalHandler();
        } else {
          console.log(err);
        }
      });
    }
  };

  //   crop image

  useEffect(() => {
    if (imageRef?.current && isModalShow) {
      const image = imageRef?.current;
      cropperRef.current = new Cropper(image, {
        movable: true,
        crop(event) {},
        responsive: true,
        dragMode: "move",
        initialAspectRatio: 1,
        modal: true,
        center: true,
        highlight: true,
        rotatable: true,
        scalable: true,
        zoomOnTouch: true,
        zoomable: true,
        zoomOnWheel: true,
        cropBoxMovable: true,
      });
    }

    return () => {
      if (cropperRef.current) {
        cropperRef.current.destroy();
      }
    };
  }, [imageRef, cropperRef, isModalShow]);

  const handleCrop = () => {
    if (cropperRef.current) {
      // Get the cropped image data (base64 format)
      const croppedDataUrl = cropperRef.current
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      setFormDataImage(croppedDataUrl);
      setPreviewURL(croppedDataUrl);
      modalHandler();
  
      // Set progress to 100% to indicate completion
      setProgress(100); // Indicate the operation is complete
      
      // Optionally, clear any existing upload errors
      setUploadError("");
    }
  };

  const cancelHandleCrop = () => {
    if (cropperRef.current) {
      cropperRef.current.destroy();
    }
    if (isModalShow) {
      modalHandler();
    }
  };

  const removeHandler = () => {
    setPreviewURL(null);
    setFormDataImage(null);
    setFiles(null);
    setConvertedSizeToMb(null);
    setFileName(null);
    setProgress(null);
    if (onRemove) {
      onRemove(); // Call the passed-in callback function
    }
  };
  

  const convertBytesToMb = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  const progressHandler = (loaded, total) => {
    const percentComplete = (loaded / total) * 100;
    return percentComplete;
  };

  // it will call when  modal close . getFormDataImage will store image object url when modal close.  when API intragetion the  progressHandler should call when  uploading image to server .
  useEffect(() => {
    if (files && getFormDataImage) {
      setProgress(progressHandler(files[0].size, files[0].size));
    }
  }, [files, getFormDataImage]);

  return (
    <>
      <div
        onDrop={(e) => onDropHandler(e)}
        onDragOver={(e) => onDropOverHandler(e)}
        onDragEnter={(e) => onDropOverHandler(e)}
        className="w-full min-h-[140px] border border-dashed  border-gray flex justify-center  items-center flex-col rounded-md px-2"
      >
        {(getFormDataImage || previewURL) && !isModalShow ? (
          <div className="w-full h-full flex justify-center items-center py-4  rounded-md  relative">
            <div className="absolute top-[2%] text-red  right-[2%]">
              <button type="button" onClick={removeHandler}>
                {Icons?.close}
              </button>
            </div>

            <figure className="flex justify-center max-w-[164px] h-[164px]   rounded-md shadow-md">
              <img
                src={previewURL || getFormDataImage}
                alt="upload image"
                className="w-full h-full rounded-lg"
                id="image-crop-container"
              />
            </figure>
          </div>
        ) : (
          <>
            {" "}
            <div className="text-center flex justify-center  items-center">
              {icon}
            </div>
            <div className="flex items-center gap-2 small-font">
              <p className="text-black my-2">Drag and drop files or</p>
              <label htmlFor="upload" className="text-primary cursor-pointer">
                browse
              </label>

              <input
                type="file"
                className="hidden"
                id="upload"
                name={name}
                required={isRequired}
                accept="image/png, image/jpeg , image/jpg"
                onChange={(e) => {
                  inputFileHandler(e);
                }}
              />
            </div>
            <p className="mt-3 small-font">
              Only JPEG and PNG with max size of 5MB
            </p>
          </>
        )}
      </div>

      <Modal
        title={"Upload image"}
        show={isModalShow}
        onHide={cancelHandleCrop}
        style={{ backgroundColor: "#F3F3F3" }}
      >
        <div className="max-w-[752px] mx-auto  flex flex-col justify-center items-center h-fit sm:max-h-[415px] rounded-md overflow-hidden  py-4">
          <figure className="flex justify-center w-full h-full overflow-hidden  rounded-md">
            <img
              src={previewURL}
              alt="upload image"
              className="w-full h-full rounded-lg"
              id="image-crop-container"
              ref={imageRef}
            />
          </figure>

          <div className="flex  sm:flex-row flex-col  items-center justify-start gap-4 w-full mt-[24px] box-border">
            <button
              type="button"
              className="bg-purple-500 text-white py-[15px] px-6 border-none outline-none text-sm rounded-md w-full sm:w-max sm:order-2 order-1 ml-4"
              onClick={handleCrop}
            >
              Apply
            </button>

            <button
              type="button"
              className="bg-white text-purple-500 border-purple-500 border-2 outline-none text-base py-3 px-8 rounded-md w-full sm:w-max sm:order-2 order-1 hover:bg-purple-500 hover:text-white"
              onClick={cancelHandleCrop}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* file upload success message and progress  */}
      {fileName && convertedSizeToMb ? (
        <div className="mt-2">
          <div className="flex items-center gap-1 ">
            {/* here I'm showing  loading and complete icons by progress state change  in real application or when API integration the  use your on  uploading  loading state instead of 'progress ' */}
            {progress && !uploadError
              ? Icons?.greenCheckMark
              : !uploadError && Icons?.loadingIcon}
            <p>
              {fileName}| {convertedSizeToMb}MB
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {progress && (
        <div className="mt-2">
          <ProgressBar
            completed={progress}
            height="8px"
            bgColor="#a33aff"
            maxCompleted={100}
            animateOnRender
            isLabelVisible={false}
          />
        </div>
      )}

      {uploadError && (
        <div className="flex justify-between items-center mt-2">
          <p className="text-red">{uploadError}</p>
          <button type="button" className="text-red">
            Retry
          </button>
        </div>
      )}
    </>
  );
}

export default FileUploader;
