/* eslint-disable react/prop-types */
import {
  AiFillHome,
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlinePlus,
} from "react-icons/ai";
import { BiLogoInstagram } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";
import { FaXTwitter } from 'react-icons/fa6';
import { IoFilterOutline, IoLocationOutline } from "react-icons/io5";
import { RxCrossCircled } from 'react-icons/rx';
import { CopyLink, DownArrow, DownArrowGray, GreenCheckMark, LeftArrow, LoadingIcon, PhotoView, RightArrow, Sneaker, ThreeDots, TransferIcon } from "./iconSvgs/IconSvgs";




export const Icons = {
  home: <AiFillHome />,
  close: <AiOutlineClose />,
  eye: <AiOutlineEye />,
  eyeHidden: <AiOutlineEyeInvisible />,
  downArrow: <DownArrow />,
  filterDropDown: <IoFilterOutline />,
  plus: <AiOutlinePlus className="text-white" />,
  location: (
    <IoLocationOutline className="common-color text-2xl font-semibold" />
  ),
  facebook: <BsFacebook />,
  instagram: <BiLogoInstagram />,
  twitter: <FaXTwitter />,
  cross: <RxCrossCircled />,
  leftArrow: <LeftArrow />,
  rightArrow: <RightArrow />,
  sneaker: <Sneaker />,
  photoView: <PhotoView />,
  loadingIcon: <LoadingIcon />,
  greenCheckMark: <GreenCheckMark />,
  downArroGray: <DownArrowGray />,
  Copy: ({ fillColor }) => <CopyLink fillColor={fillColor} />,
  threeDots: <ThreeDots />,
  Transfer: ({ fillColor }) => <TransferIcon fillColor={fillColor} />,
};
