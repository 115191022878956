
import Accordion from './../../../components/ul/accordion/Accordion';
import style from "./Faq.module.css";
const Faq = () => {
  return (
    <div className="py-[40px] md:py-[56px]">
      <h1 className='heading mb-[24px]'>Frequently asked questions</h1>
      <div
        className={`${style.faq} customShadow rounded-md   text-base p-[24px]`}
      >
        <Accordion headingTitle={"Where do I get my shoes authenticated?"}>
          <div>
            <p className="py-4 text-[#545454]">
              You can get your shoes authenticated at any store under our store list tab. These are the only stores to get VRFYTags.
            </p>
          </div>
        </Accordion>
        <Accordion headingTitle={"How can I transfer ownership?"}>
          <div>
            <p className="py-4 text-[#545454]">
            Here are the necessary steps to transfer ownership:
            </p>

            <ul className="text-[#545454]">
              <li>
                <span>Confirm that the recipient has an active VRFY account.</span>
              </li>
              <li>
                <span>Verify the recipient username and email address associated with their VRFY account. Note that ownership transfer is irreversible.</span>
              </li>
              <li>
                <span>
                Log in to your VRFY account and select the Transfer Ownership option.
                </span>
              </li>
              <li>
                <span>
                Enter the recipient’s details as prompted.
                </span>
              </li>
              <li>
                <span>
                Advise the recipient to refresh their account page to confirm the transfer.
                </span>
              </li>
            </ul>
          </div>
        </Accordion>
        <Accordion headingTitle={"How Can I Access the Chip in My Shoe?"}>
          <div>
            <p className="py-4 text-[#545454]">
            To access the chip embedded in your shoe, locate the chip position within the shoe and gently tap that area with your device. 
            This action should prompt your device to display the digital profile of your VRFY item.
            </p>
          </div>
        </Accordion>
        <Accordion headingTitle={"Adding New Shoes to My Inventory: How Is It Done?"}>
          <div>
            <p className="py-4 text-[#545454]">
            Only items purchased or authenticated at our verified and partnered stores can be added to your inventory. 
            To add new shoes, please visit one of our partner stores for purchasing or to get your shoes authenticated.
            </p>
            
          </div>
        </Accordion>
        <Accordion headingTitle={"What is VRFY"}>
          <div>
            <p className="py-4 text-[#545454]">
            VRFY is an innovative service that connects physical items with a comprehensive digital identity. 
            This digital profile enhances the value, security, and authenticity of the physical items.
            </p>
            
          </div>
        </Accordion>
        {/* <Accordion headingTitle={"How to return an item"}>
          <div>
            <p className="py-4 text-[#545454]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              in diam quis felis egestas placerat sed nec eros. Praesent
              ultricies, leo eu vulputate congue, leo nisi viverra sapien, ac
              egestas sapien lorem id tortor. Sed tincidunt, metus eget mattis
              bibendum, purus diam porttitor massa, euismod facilisis arcu metus
              a ex. Mauris venenatis faucibus nulla in blandit. Mauris facilisis
              nec mauris eu rutrum. Donec est nibh, laoreet eget accumsan et,
              aliquet at tellus. Quisque nulla justo, egestas sed semper a,
              efficitur sit amet justo. Etiam a felis euismod purus cursus
              laoreet. Nam elit lorem, pulvinar quis massa at, ornare consequat
              augue. Pellentesque volutpat purus nec nulla bibendum aliquam.
              Proin tempus nec sem hendrerit porttitor. Nulla fringilla accumsan
              mauris vitae egestas. Aenean rutrum et neque at faucibus.
            </p>
            <p className="pt-4 text-[#545454]">
              <span className="font-semibold block">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </span>
              Mauris faucibus, est iaculis pretium rhoncus, enim lacus lobortis
              eros, vehicula eleifend velit quam ac lectus. Vestibulum non ipsum
              ut diam suscipit lobortis vitae in tellus. Quisque convallis
              rhoncus viverra. Sed sit amet nisl vitae velit interdum aliquam a
              sed risus. Sed nibh tellus, convallis euismod nibh non, tincidunt
              ultrices arcu. Donec ac lacus tristique, egestas justo vulputate,
              euismod mi. Donec quis finibus sapien, sed aliquet lacus.
              Pellentesque tempus hendrerit odio, eget hendrerit dolor feugiat
              et. Nullam ac hendrerit arcu. Mauris vitae efficitur enim.
            </p>

            <ul className="text-[#545454]">
              <li>
                <span>Mauris faucibus, est iaculis pretium rhoncus</span>
              </li>
              <li>
                <span>Mauris faucibus, est iaculis pretium rhoncus</span>
              </li>
              <li>
                <span>
                  Enim lacus lobortis eros, vehicula eleifend velit quam ac
                  lectus
                </span>
              </li>
            </ul>
          </div>
        </Accordion> */}
      </div>
    </div>
  );
};

export default Faq;
