import shoes from '../../assets/shoes.png';


export const header = [
  { id: 1, text: "Item details" },
  { id: 2, text: "Authentication date" },
  { id: 3, text: "Condition" },
];




export const rows = Array.from({ length: 100 }, (_, index) => ({
  id: index + 1,
  image: shoes,
  title: `Jordan Retro ${index + 1} High`,
  gender: "Men",
  size: "12 U.S",
  date: "14 Aug 2021",
  condition: `New ${index + 1}`,
}));
