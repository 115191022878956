import propType from "prop-types";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Icons } from "../../../shared/lib/Icon";

function Modal({
  show,
  onHide,
  headerComponent,
  children,
  footerComponent,
  title,
  isOverlayClose = true,
  id,
  ...props
}) {

  
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "auto";
    }
  }, [show]);


  if (!show) return;

  return ReactDOM.createPortal(


    <div id={id ? id : ""} aria-modal   className="fixed  top-0 left-0 w-full h-full  flex flex-col justify-center items-center z-[9999998]"  >

      <div
        className="fixed  w-full h-full bg-black-alpha-60 top-0 left-0 transition-opacity duration-500 z-[9999998]"
        onClick={isOverlayClose ? onHide : () => {}}
        data-type="modal-close"
        aria-label="modal-close"
      ></div>

      <div
        className="md:max-w-[800px]  h-fit w-[95%] absolute top-[50%] left-[50%] right-[50%] transform translate-x-[-50%] bg-slate-100  translate-y-[-50%]  px-4 py-2 rounded-md z-[9999999] shadow-2xl"
        {...props}
        data-type="modal-body"
        aria-label="modal-body"
      >
        {/* modal header  */}
        {headerComponent ? (
          headerComponent
        ) : (
          <div className="flex justify-between items-center text-[1.5rem] mb-2">
            <h2 className="heading sm:!text-[1.7rem] !text-[1rem]">
              {title || "Modal"}
            </h2>
            <button
              type="button"
              onClick={onHide}
              className="hover:text-slate-600 transition-colors duration-200"
              role="button"
              aria-label="modal-close-btn"
            >
              {Icons.close}
            </button>
          </div>
        )}
        {/* body  */}
        <div>{children}</div>
        {/* footer  */}
        {footerComponent && footerComponent}
      </div>
    </div>,
    document.body
  );
}

Modal.propTypes = {
  show: propType.bool.isRequired,
  onHide: propType.func.isRequired,
  headerComponent: propType.element,
  children: propType.element,
  footerComponent: propType.element,
  title: propType.string,
  isOverlayClose: propType.bool,
};

export default Modal;
