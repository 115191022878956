/* eslint-disable react/prop-types */

import { useState } from "react";

function CustomTextArea({
  placeHolder,
  isRequired = true,
  className,
  id,
  name,
  type = "text",
  onChange,
  ...props
}) {
  const [isDisplay, setIsDisplay] = useState(true);

  //   const onfocusHandler = () => {
  //     setIsDisplay(false);
  //   };

  const onChangeHandler = (e) => {
    if (typeof onChange === "function") {
      onChange(e);
    }

    if (e.target.value) {
      setIsDisplay(false);
    }

    if (!e.target.value) {
      setIsDisplay(true);
    }
  };

  return (
    <div className="relative">
      <textarea
        className={`w-full py-3 px-4 outline-none border border-[#F3F3F3] rounded-md shadow hover:border-primary text-black ${className}`}
        type={type}
        name={name}
        id={id}
        required={isRequired}
        // onFocus={onfocusHandler}
        onChange={onChangeHandler}
        {...props}
      />
      {isDisplay && (
        <p className="absolute top-[25px] transform translate-y-[-50%]  left-[1.2rem] text-[#888] select-none pointer-events-none">
          {placeHolder} {isRequired && <span className="text-red">*</span>}
        </p>
      )}
    </div>
  );
}

export default CustomTextArea;
