import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Avatar, icon1, logo } from "../../../shared/images";
import { Icons } from "../../../shared/lib/Icon";
import styles from "./Navbar.module.css";
import { getToken, getTokenByName } from "../../../apis/auth/authAPI";



const navData = [
  {
    label: "Home",
    pathUrl: "/home",
  },
  {
    label: "Dashboard",
    pathUrl: "/dashboard",
  },
  {
    label: "Store list",
    pathUrl: "/store-list",
  },
  {
    label: "FAQ",
    pathUrl: "/faq",
  },
  {
    label: "Contact us",
    pathUrl: "/contact-us",
  },
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [picture, setPicture] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // if (location?.pathname === "/home") {
    if (!getToken()) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      const picture = getTokenByName('picture') != "undefined" ? getTokenByName('picture') : Avatar;

      setPicture(picture);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="w-full py-[16px] ">
      <div className="customContainer">
        <div
          onClick={toggleMenu}
          className={`fixed transition-opacity duration-300 ease h-full w-full top-0 left-0 bg-black-alpha-60 md:hidden ${
            isMenuOpen ? "opacity-1 z-40 block" : "opacity-0 -z-40 hidden"
          }`}
        ></div>

<nav className="flex items-center justify-between w-full">
  <Link to="/home">
    <img src={logo} alt="Logo" className="w-26 lg:h-2rem h-8 pr-8" />
  </Link>

  {/* Navigation links and toggle menu button */}
  <div className="flex items-center flex-grow">
    <div className={`flex-grow lg:flex md:flex items-center gap-6 ${isMenuOpen ? "hidden" : "hidden md:flex"}`}>
      {(isLoggedIn ? navData.filter(({ label }) => label !== "Home") : navData).map(({ pathUrl, label }, index) => (
        <NavLink
          key={index}
          to={pathUrl}
          className={`lg:px-3 ${styles.navbar_li} ${location.pathname === pathUrl ? styles.activeLink : ""}`}
        >
          {label}
        </NavLink>
      ))}
    </div>

    
  </div>
  <button onClick={toggleMenu} className="md:hidden">
      <img src={icon1} alt="Menu Icon" className="w-26 lg:h-12 md:h-10 h-8" />
    </button>

  {/* Profile or Login/SignUp based on isLoggedIn */}
  {isLoggedIn ? (
    <NavLink to={"/profile"} className="hidden md:block">
      <img  src={picture? picture : Avatar} alt="Profile" className={`lg:inline md:inline ${styles.navbar_profile}`} />
    </NavLink>
  ) : (
    <NavLink to={"/auth"} className="text-primary text-[18px] md:text-[16px] hidden md:block">
      Login/Sign up
    </NavLink>
  )}
</nav>


        <ul
          className={`fixed z-50 bg-white w-[70%] sm:w-[50%] h-full transition-all overflow-x-hidden flex flex-col p-5 pt-[50px] top-0 ${
            isMenuOpen ? "!right-0" : "-right-[100%]"
          }`}
          
        >
            <li>
            {isLoggedIn && (
              <NavLink to={"/profile"} onClick={toggleMenu}>
                <img
                  src={picture}
                  alt="profile picture"
                  className={`lg:inline mt-3  ${styles.navbar_profile} ${
                    isMenuOpen ? "" : "hidden"
                  }`}
                />
              </NavLink>
            )}
          </li>

          <button onClick={toggleMenu} className="absolute top-[10px] left-1 w-[30px] h-[30px] bg-primary rounded-full text-white flex justify-center items-center">
            <span className="text-[1.3rem] font-bold">{Icons?.close}</span>
          </button>
          

          {navData.filter(({ label }) => !isLoggedIn || label !== "Home").map(({ pathUrl, label }, index) => (
            <li key={index} className="pt-3 text-center">
              <NavLink to={pathUrl} onClick={toggleMenu} className={`${styles.navbar_li} ${location.pathname === pathUrl ? "text-primary" : ""}`}>
                {label}
              </NavLink>
            </li>
          ))}

          {!isLoggedIn && (
            <li className="pt-3 text-center">
              <NavLink to={"/auth"} className="text-[18px] md:text-[16px]">
                Login/Sign up
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
