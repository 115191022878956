import propTypes from "prop-types";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    border: "1px solid #F3F3F3",
    padding: "6px 5px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.09)",
    "&:hover": {
      border: "1px solid #F3F3F3",
    },
    "&:focus": {
      border: "1px solid #F3F3F3",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#888",
  }),
  option: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
  }),
};

function CustomSelect({
  options,
  placeholder = "Select",
  isRequired,
  className,
  name,
  onChange
}) {
  const CustomIndicatorSeparator = () => null;

  const onChangeHandler = (e) => {
    if (typeof onChange === "function") {
      
      onChange({name, value: e?.value});
    }
  }

  return (
    <div className= {`${isRequired ? 'custom_select_component' : ''} bg-transparent`}>
      <Select
        options={options}
        styles={customStyles}
        isSearchable={true}
        placeholder={placeholder}
        required={isRequired}
        components={{
          IndicatorSeparator: CustomIndicatorSeparator, // Use the custom IndicatorSeparator component
        }}
        className={className}
        name={name}
        onChange={onChangeHandler}
      />
    </div>
  );
}

CustomSelect.propTypes = {
  options: propTypes.array.isRequired,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
  className: propTypes.string
};

export default CustomSelect;
