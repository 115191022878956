/* eslint-disable react/prop-types */
import { useState } from "react";
import Checkbox from "../../widgets/checkbox/Checkbox";
import Accordion from "../accordion/Accordion";
import DatePicker from "../datepicker/Datepicker";
("../../../shared/lib/Icon");

function Filter({ isClear, onClear, onChange, onFromDateChange, onToDateChange }) {
  
  const borderStyles =
    "border-[1px] border-slate-200 checked:border-[5px] checked:border-primary transition duration-300 w-[19px] h-[19px] border-spacing-2  rounded-full appearance-none bg-transparent ";
    // eslint-disable-next-line no-unused-vars
    const [checkboxValue, setCheckboxValue] = useState(null);

  console.log(checkboxValue);
  const onChangeHandler = (e) => {
    if (typeof onChange === "function") {
      onChange(e.target.value);
    }
  }

  const conditionOptions = [
    "Deadstock/New", "Excellent", "Good", "Fair", "Worn", "Poor"
  ];

  return (
    <form className="px-2 flex flex-col gap-3 w-full" id="sort_form">
      {/* sort by  */}

      <Accordion headingTitle={"Sort by"}>
        <div
          className="flex flex-col justify-center gap-1  py-1"
          onChange={onChangeHandler}
        >
          <div className="  py-[6px] flex  items-center gap-2">
            <input
              type="radio"
              id="sort-by"
              name="sort-by"
              value="az"
              className={`${borderStyles}`}
            />
            <label htmlFor="sort-by">Alphabetically: A-Z</label>
          </div>
          <div className="pb-[8px] flex  items-center gap-2">
            <input
              type="radio"
              id="sort-by-2"
              name="sort-by"
              value="za"
              className={`${borderStyles}`}
            />
            <label htmlFor="sort-by-2">Alphabetically: Z-A</label>
          </div>
        </div>
      </Accordion>

      <Accordion headingTitle={"Date Added"}>
        <div
          className="flex flex-col justify-center gap-1  py-1"
          onChange={onChangeHandler}
        >
          <div className="  py-[6px] flex  items-center gap-2">
            <input
              type="radio"
              id="newest-oldest"
              name="sort-by"
              value="no"
              className={`${borderStyles}`}
            />
            <label htmlFor="sort-by">Newest To Oldest</label>
          </div>
          <div className="pb-[8px] flex  items-center gap-2">
            <input
              type="radio"
              id="oldest-newest"
              name="sort-by"
              value="on"
              className={`${borderStyles}`}
            />
            <label htmlFor="sort-by-2">Oldest To Newest</label>
          </div>
        </div>
      </Accordion>

      {/* date pick  */}

      {/* <Accordion headingTitle={"Authentication date"}>
        <div className="flex flex-col w-full justify-center gap-2  py-1">
          <DatePicker
            isRequired
            title={"From"}
            isClear={isClear}
            onOpen={() => {
              onClear(false);
            }}
            getDate={date => onFromDateChange(date)}
          />
          <DatePicker
            isRequired
            title={"To"}
            isClear={isClear}
            onOpen={() => {
              onClear();
            }}
            getDate={date => onToDateChange(date)}
          />
        </div>
      </Accordion> */}

      {/* Condition Accordion */}
      <Accordion headingTitle={"Condition"}>
        <div className="flex flex-col gap-2 mt-2">
          {conditionOptions.map((condition, index) => (
            <Checkbox
              key={condition} // Unique key for each checkbox
              name={condition}
              isReset={isClear}
              id={index.toString()}
              onChange={(e) => {
                onClear();
                setCheckboxValue({
                  name: e?.target?.name,
                  isChecked: e?.target?.checked,
                });
                onChange(e?.target?.name);
              }}
            >
              {condition}
            </Checkbox>
          ))}
        </div>
      </Accordion>
      
    </form>
  );
}

export default Filter;
