/* eslint-disable react/prop-types */
import { header } from "../tableData";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

// eslint-disable-next-line react/prop-types
function Table({data , isProducts ,isInventory, getMessage}) {
  return (
    <div className="dashboard  h-fit py-6  overflow-x-auto">
      <table className="w-full h-fit  md:min-w-full  min-w-[700px]  rounded-xl select-none small-font">
        <thead className="customShadow  ">
          <tr>
            <TableHeader data={header} className="text-start py-4  px-3 " />
          </tr>
        </thead>
        {/* table body  */}

        <tbody className="h-fit bg-white">
          {typeof data == "object" && data?.map((row) => (
            <TableRow
              data={row}
              key={row?.id}
              className={"py-[16px] px-3 "}
              isInventory = {isInventory}
              isProducts = {isProducts}
              getMessage = {getMessage}  
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
