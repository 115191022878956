/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TopPlaceHolderInput } from "../../components/widgets/customInput/CustomInput";

import { useNavigate } from "react-router-dom";
import { loginCognitoUser } from "../../apis/auth/authAPI";

const Login = () => {
  const [eye, setEye] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrormessage] = useState("");
  const navigate = useNavigate();

  function handleSubmit (e) {
    e.preventDefault();
    loginCognitoUser(username, password)
      .then((data)=>{
        console.log(data);
        navigate('/');
        setErrormessage("");
      },(err)=>{
        console.log(err);
        setErrormessage("Incorrect username or password. Please try again.");
      })
      .catch(err=> {
        console.log(err);
        setErrormessage("Incorrect username or password. Please try again.");
      });
  }


  return (
    <>
      {errorMessage &&
        <div class="text-red px-4 py-3 rounded relative" role="alert">
          <span class="block sm:inline">
            {errorMessage}
          </span>
        </div>
      }
      <form onSubmit={handleSubmit}>
      {/* username & email */}
      <div className="w-full">
        <TopPlaceHolderInput
          type="text"
          placeHolder="Username or email address"
          isRequired
          value={username}
          htmlFor={"username"}
          name={"username"}
          onChange={e => setUsername(e.target.value)}
        />
      </div>
      {/* password */}
      <div className="w-full mt-[24px]">
        <TopPlaceHolderInput
          type="password"
          isRequired
          placeHolder="Password"
          value = {password}
          htmlFor={"password"}
          name={"password"}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      {/* forgot password */}
      <div className="w-full text-right mb-6 mt-3 select-none">
        <Link className="text-black text-sm hover:underline" to="/auth/forgot">
          Forgot password?
        </Link>
      </div>
      {/* login button */}
      <div className="w-full sm:mb-2 mb-4">
        <button type="submit" className="w-full py-[19px] px-4 bg-primary text-white rounded-[4px] hover:bg-purple-600 hover:text-white text-sm">
          Login
        </button>
      </div>
      </form>
      {/* trams & conditions */}
      <div className="w-full">
        <p className="w-full text-black text-sm">
          By logging in, you agree to the{" "}
          <Link to="/terms_service" className="underline">
            Terms of Service{" "}
          </Link>
          and{" "}
          <Link className="underline" to="/privacy_policy">
            Privacy Policy
          </Link>
        </p>
      </div>
    </>
  );
};

export default Login;