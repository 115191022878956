/* eslint-disable react/prop-types */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../../assets/profile.png";
import Modal from "../../../components/ul/modal/Modal";
import {
  TopPlaceHolderInput,
} from "../../../components/widgets/customInput/CustomInput";
import ProfileUploader from "./ProfileUploader";
import { getTokenByName, removeToken } from "../../../apis/auth/authAPI";
import { updateCongintoUser } from "../../../apis/auth/authAPI";

// a child form component
const EditForm = ({ data, editHideHandle }) => {
  // Define state variables for form inputs
  const [firstName, setFirstName] = useState(
    data?.firstName ? data.firstName : ""
  );
  const [lastName, setLastName] = useState(data?.lastName ? data.lastName : "");
  const [username, setUsername] = useState(data?.username ? data.username : "");
  const [email, setEmail] = useState(data?.email ? data.email : "");
  console.log("PASSSS")
  console.log(data.oldPassword)
  const [oldPassword, setOldPassword] = useState(
    data?.oldPassword ? data.oldPassword : "**********"
  );
  const [newPassword, setNewPassword] = useState("");

  const navigate = useNavigate();
  // Handle input changes
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  // TO DO : EDIT PROFILE SUBMIT FUNCTION
  const handleSubmit = (e) => {
    e.preventDefault();
    updateCongintoUser(firstName, lastName, username, email, oldPassword, newPassword)
    .then(async () => {
      editHideHandle();
    }).catch(err => {
      // display error
      console.error(err);
    });
  }

  return (
    <div className="bg-white p-[24px] rounded-md customShadow">
      <form onSubmit={handleSubmit} className="flex flex-col gap-[24px]" id="editForm">
        {/* first name and last name  */}
        <div className="flex sm:flex-row flex-col w-full gap-[24px] ">
          {/* first name  */}
          <div className="w-full">
            <TopPlaceHolderInput
              placeHolder="First name"
              type="text"
              htmlFor={"firstName"}
              name={"FirstName"}
              value={firstName}
              isFocus={false}
              onChange={(e) => {
                handleFirstNameChange(e);
              }}
            />
          </div>

          {/* last name  */}
          <div className="w-full">
            <TopPlaceHolderInput
              placeHolder="Last name"
              type="text"
              htmlFor={"lastName"}
              name={"lastName"}
              isFocus={false}
              value={lastName}
              onChange={(e) => {
                handleLastNameChange(e);
              }}
            />
          </div>
        </div>

        {/* user name  */}
        <TopPlaceHolderInput
          placeHolder="Username"
          type="text"
          htmlFor={"username"}
          name={"username"}
          value={username}
          isFocus={false}
          onChange={(e) => {
            handleUsernameChange(e);
          }}
          readOnly
        />

        {/* email  */}
        <TopPlaceHolderInput
          placeHolder="Email address"
          type="email"
          htmlFor={"email"}
          name={"email"}
          value={email}
          isFocus={false}
          onChange={(e) => {
            handleEmailChange(e);
          }}
          readOnly
        />

        {/* old password  */}
        <TopPlaceHolderInput
          placeHolder="Password"
          type="password"
          htmlFor={"oldPassword"}
          name={"oldPassword"}
          isFocus={false}
          value={oldPassword}
          onChange={(e) => {
            handleOldPasswordChange(e);
          }}
          readOnly
        />

        {/* password   */}
        {/* <div className="w-full">
          <TopPlaceHolderInput
            placeHolder={"New password"}
            htmlFor={"newPassword"}
            type="password"
            isRequired
            isFocus = {false}
            value={newPassword}
            onChange={(e) => {
              handleNewPasswordChange(e);
            }}
          />
        </div> */}

      {/* button group  */}
      <div className="mt-[24px] flex gap-4 items-center">
        <button
          type="button"
          className="outline outline-[1.8px] outline-primary py-[19px] px-[26px] text-primary rounded-md small-font"
          onClick={editHideHandle}
        >
          Cancel
        </button>

        <button
          type="submit"
          form="editForm"
          className="bg-primary text-white  py-[20px] px-[32px] rounded-md  small-font"
        >
          Save
        </button>
      </div>
      </form>
    </div>
  );
};

// profile parent component

function Profile() {
  const [isEdit, setIsEdit] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [user] = useState({
    userName: getTokenByName('userName'),
    firstName: getTokenByName('firstName'),
    lastName: getTokenByName('lastName'),
    email: getTokenByName('email'),
    picture: getTokenByName('picture') != "undefined" ? getTokenByName('picture') : avatar,
    // since: "03 Aug 2023",
  });

  const showEditFormHandle = () => {
    setIsEdit(true);
  };

  const logout = () => {
    removeToken();
    navigate('/');
  }

  const hideEditFormHandle = () => {
    setIsEdit(false);
  };

  const modalShowHandler = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="customContainer">
      <div className="md:py-[56px] py-[40px]">
        <h2 className="heading  ">Profile management</h2>

        <div className="flex lg:flex-row flex-col  gap-8 mt-[24px]">
          {/* profile photo */}
          <div className=" lg:w-[30%] w-full">
            <div className="flex flex-col justify-center items-center bg-white rounded-md customShadow py-6 px-3">
              {/* avatar  */}
              <div className="w-[128px] h-[128px] rounded-full outline outline-[1px] outline-primary outline-offset-4 flex flex-col justify-center items-center relative">
                {/* edit icon  */}
                <button
                  type="button"
                  className="w-[30px] h-[30px] flex justify-center items-center bg-white absolute top-0 right-1 rounded-full border border-gray"
                  onClick={modalShowHandler}
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.88082 10.6211H2.85068L8.37397 5.10534L7.40411 4.1368L1.88082 9.65253V10.6211ZM11.3 4.15321L8.35753 1.21477L9.16301 0.410397C9.43699 0.136799 9.76301 0 10.1411 0C10.5192 0 10.8452 0.136799 11.1192 0.410397L12.1055 1.39535C12.3685 1.658 12.5 1.98358 12.5 2.37209C12.5 2.7606 12.3685 3.08618 12.1055 3.34884L11.3 4.15321ZM10.3301 5.12175L3.44247 12H0.5V9.06156L7.38767 2.18331L10.3301 5.12175Z"
                      fill="#A5A5A5"
                    />
                  </svg>
                </button>
                {/* image  */}
                <img src={user.picture} className="w-full h-full" />
              </div>

              <h3 className="heading">{user.firstName} {user.lastName}</h3>
              {/* <p className="small-font">Member since {user.since}</p> */}

            {/* logout button  */}
            <button
              type="button"
              className="text-[1.2rem] justify-center py-[12px] px-[28px] border border-primary text-primary flex items-center gap-2  rounded-lg  w-full sm:w-fit "
              onClick={logout}
            >
              <span>Logout</span>
            </button>
            </div>

            <Modal
              show={showModal}
              onHide={modalShowHandler}
              style={{
                maxWidth: "368px",
              }}
              title={"Upload profile picture"}
            >
              <ProfileUploader
                onHide={modalShowHandler}
                picture={user.picture}
              />
            </Modal>
          </div>

          {/* profile photo end    */}

          {/* details  */}
          <div
            className={`flex flex-col  gap-2 bg-white  rounded-md customShadow lg:w-[70%] py-4 justify-between px-4 ${
              isEdit ? "hidden" : "flex"
            }`}
          >
            <p className="small-fon">
              Username: <span className="font-bold">{user.userName}</span>
            </p>
            <p className="small-fon">
              Email: <span className="font-bold">{user.email}</span>
            </p>

            <p className="small-fon">
              Password: <span className="font-bold">**********</span>
            </p>

            {/* edit button  */}
            <button
              type="submit"
              className="text-[1.2rem] justify-center py-[12px] px-[28px] border border-primary text-primary flex items-center gap-2  rounded-lg  w-full sm:w-fit "
              onClick={showEditFormHandle}
            >
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.38082 12.6211H4.35068L9.87397 7.10534L8.90411 6.1368L3.38082 11.6525V12.6211ZM12.8 6.15321L9.85753 3.21477L10.663 2.4104C10.937 2.1368 11.263 2 11.6411 2C12.0192 2 12.3452 2.1368 12.6192 2.4104L13.6055 3.39535C13.8685 3.658 14 3.98358 14 4.37209C14 4.7606 13.8685 5.08618 13.6055 5.34884L12.8 6.15321ZM11.8301 7.12175L4.94247 14H2V11.0616L8.88767 4.18331L11.8301 7.12175Z"
                  fill="#A33AFF"
                />
              </svg>

              <span>Edit</span>
            </button>
          </div>

          <div className={`${isEdit ? "block lg:w-[70%] w-full" : "hidden"}`}>
            <EditForm
              data={{
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.userName,
                email: user.email,
                oldPassword: "",
              }}
              editHideHandle={hideEditFormHandle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
