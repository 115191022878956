import { NavLink } from "react-router-dom";
import { useState } from "react";
import CustomInput from "../../../components/widgets/customInput/CustomInput";
import CustomTextArea from "../../../components/widgets/customInput/CustomTextarea";

const ContactUs = () => {
  // State hooks for form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = async (e) => {
    // Prevent default form submission

    const formData = {
      name,
      email,
      message,
    };

    try {
      const response = await fetch("https://xdewq4i0tc.execute-api.us-east-2.amazonaws.com/default/contact-us-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success - perhaps clear the form or show a success message
        console.log("Email sent successfully");
      } else {
        // Handle errors - perhaps show an error message
        console.log("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="md:py-[56px] py-[40px]">
      <h1 className="heading">Contact us</h1>
      <div className="bg-white px-4 py-8 rounded-md mt-[24px]">
        <div className="lg:w-[50%] w-full">
          <form onSubmit={handleSubmit}>
            <div className="flex md:flex-row flex-col gap-[24px] items-center w-full md:w-auto">
              <div className="w-full">
                <CustomInput
                  type="text"
                  placeHolder={"Full name"}
                  isRequired
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full">
                <CustomInput
                  type="email"
                  placeHolder={"Email address"}
                  isRequired
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-[24px]">
              <div className="w-full">
                <CustomTextArea
                  placeHolder={"Message"}
                  isRequired
                  className={"min-h-[127px] resize-none"}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <button
              type="submit"
              className="small-font py-[19px] px-[16px] bg-primary text-white mt-[24px] rounded-lg w-full sm:w-auto"
            >
              Send message
            </button>
          </form>

          <div className="text-center text-[#A5A5A5] flex w-full items-center mt-[30px]">
            <hr className="block w-[50%] h-[1px] bg-[#A5A5A5]" />
            <p className="mx-1">OR</p>
            <hr className="block w-[50%] h-[1px] bg-[#A5A5A5]" />
          </div>

          <p className="small-font my-6">Have a frequently asked question?</p>

          <NavLink
            to={"/faq"}
            className="small-font py-[19px] px-[16px] outline outline-[2px] outline-primary text-black mt-[24px] rounded-lg w-full text-center sm:w-fit block"
          >
            Go to our FAQ page
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

