/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/widgets/footer";
import Navbar from "../components/widgets/header/Navbar";

const MainLayout = () => {
  return (
    <div className="layout">
      <header>
        <Navbar />
      </header>

      <main className="customContainer">
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MainLayout;
