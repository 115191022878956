import propType from "prop-types";
import { useState } from "react";
import { Icons } from "../../../shared/lib/Icon";

function Accordion({ headingTitle, children }) {
  const [isCollapse, setIsCollapse] = useState(true);

  const collapseHandler = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <>
      <div className="w-auto">
        <button
          type="button"
          className="flex justify-between items-center w-full "
          onClick={collapseHandler}
        >
          <span>{headingTitle ? headingTitle : "Title"}</span>
          <span
            className={` transition duration-400 ${
              !isCollapse ? "rotate-[-180deg]" : ""
            }`}
          >
            {" "}
            {Icons.downArrow}
          </span>
        </button>

        <div
          className={`grid  overflow-hidden transition-all  duration-400 ${
            isCollapse ? "grid-rows-[0fr]" : "grid-rows-[1fr] overflow-visible"
          } `}
        >
          <div
            className={`transition-[opacity] duration-500 overflow-hidden ${
              isCollapse ? "opacity-0" : "opacity-100"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

Accordion.propTypes = {
  children: propType.element,
  headingTitle: propType.string,
};

export default Accordion;
