import { createBrowserRouter } from "react-router-dom";

import MainLayout from "../layout/MainLayout";
import Auth from "../views/auth/Auth";
import AuthSuccess from "../views/auth-success/Auth";
import ForgotPassword from "../views/auth/ForgotPassword";
import SetNewPassword from "../views/auth/SetNewPassword";
import SuccessMessage from "../views/auth/SuccessMessage";
import SignUpCode from "../views/auth/SignUpCode";

import PrivacyPolicy from "../views/pages/terms/PrivacyPolicy";
import TermsServices from "../views/pages/terms/TermsServices";

import AuthLayout from "../views/auth/AuthLayout";
import AddItem from '../views/pages/addItem/AddItem';
import ContactUs from "../views/pages/contactUs/ContactUs";
import Dashboard from "../views/pages/dashboard/Dashboard";
import Faq from "../views/pages/faq/Faq";
import Home from "../views/pages/home/Home";
import Profile from "../views/pages/profile/Profile";
import SingleProductPage from "../views/pages/singleProduct/SingleProductPage";
import StoreList from "../views/pages/storeList/StoreList";
import TermsCondition from "../views/pages/terms/TermsCondition";
import RouteRedirect from "./RouteRedirect"


const router = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      {
        path: "forgot",
        element: <ForgotPassword />,
      },
      {
        path: "new-password",
        element: <SetNewPassword />,
      },
      {
        path: "success",
        element: <SuccessMessage />,
      },
      {
        path: "signup-code",
        element: <SignUpCode />,
      },
    ],
  },

  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <RouteRedirect />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/home",
        element: <Home />,
      },

      {
        path: "/store-list",
        element: <StoreList />,
      },

      {
        path: "/auth-success",
        element: <AuthSuccess />,
      },

      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/terms-service",
        element: <TermsServices />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-conditions",
        element: <TermsCondition />,
      },
      {
        path: "/products/:productId",
        element: <SingleProductPage />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/add-item",
        element: <AddItem />,
      },
    ],
  },
]);

export default router;
