/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TopPlaceHolderInput } from "../../components/widgets/customInput/CustomInput";
import CustomSelect from "../../components/ul/select/Select";
import { useNavigate } from "react-router-dom";
import { signupCognitoUser } from "../../apis/auth/authAPI";

const SignUp = () => {
  const [eye, setEye] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [isUnderAge, setIsUnderAge] = useState(false);


  const [errorMessage, setErrormessage] = useState("");

  const navigate = useNavigate();

  const handleBirthdateChange = (e) => {
    console.log(e.target.value);
    setBirthdate(e.target.value);

    // Calculate age and check if under 13
    const birthdate = new Date(e.target.value);
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    const m = today.getMonth() - birthdate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }

    if (age < 13) {
      // Set isUnderAge to true if the user is under 13
      setIsUnderAge(true);
    } else {
      // Otherwise, ensure isUnderAge is false
      setIsUnderAge(false);
    }
  };


  function handleSubmit(e) {
    e.preventDefault();
    if (isUnderAge) {
      setErrormessage("User must be 13 years or older.");
      return; // Prevent the form submission
    }
  
    if (password != password2) {
      setErrormessage("Please make sure your passowrds match.");
      return;
    }
    signupCognitoUser(firstName, lastName, userName, email, password, gender, birthdate)
    .then(async () => {
      // navigate to input Your confirmation code
      navigate('/auth/signup-code', { state: userName });
      setErrormessage("");
    }).catch(err => {
      // display error
      setErrormessage("User already exists. Please try again.");
    });
  }

  return (
    <>
      {errorMessage &&
        <div class="text-red px-4 py-3 rounded relative" role="alert">
          <span class="block sm:inline">
            {errorMessage}
          </span>
        </div>
      }
      <form onSubmit={handleSubmit}>
      <div className="w-full sm:flex-row flex-col flex items-center mb-6 sm:gap-[8px] gap-[24px]">
        {/* name */}
        <div className="w-full">
          <TopPlaceHolderInput
            placeHolder="First name"
            isRequired
            type="text"
            value=""
            name="firstName"
            htmlFor="firstName"
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        {/* last name */}
        <div className="w-full">
          <TopPlaceHolderInput
            type="text"
            placeHolder="Last name"
            isRequired
            value={""}
            htmlFor={"lastName"}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
      </div>
      {/* username */}
      <div className="w-full">
        <TopPlaceHolderInput
          type="text"
          placeHolder="Username"
          isRequired
          value={""}
          htmlFor={"username"}
          onChange={e => setUserName(e.target.value)}
        />
      </div>
      {/* email */}
      <div className="w-full my-[24px]">
        <TopPlaceHolderInput
          type="email"
          placeHolder="Email"
          isRequired
          value={""}
          htmlFor={"email"}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      {/* gender Female, Male, Other */}
      <div className="w-full my-[24px]">
        <CustomSelect
          name="gender"
          options={[
            { value: "Female", label: "Female" },
            { value: "Male", label: "Male" },
            { value: "Other", label: "Other" },
          ]}
          placeholder="Gender"
          onChange={e => setGender(e?.value)}
        />
      </div>
      {/* birthdate day,month,year */}
      <div className="w-full my-[24px]">
        <TopPlaceHolderInput
          type="date"
          placeHolder="Birth date"
          value={birthdate}
          htmlFor={"birthdate"}
          onChange={handleBirthdateChange}
        />
        {isUnderAge && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            VRFY users must be 13 years or older
          </div>
        )}
      </div>
      {/* password */}
      <div className="w-ful">
        <TopPlaceHolderInput
          type="password"
          placeHolder="Password"
          isRequired
          value={""}
          htmlFor={"password"}
          onChange={e => setPassword(e.target.value)}
        />
      </div>

      {/* password validation */}
      {/* symbol */}
      <div className="w-full flex flex-col sm:gap-0 gap-[8px] mt-2">
        <div className="flex sm:flex-row flex-col sm:items-center items-start sm:gap-3 gap-[8px] w-full">
          <div className="flex items-center gap-1">
            {password?.match(/.{8,}$/)
              ? <div className="bg-[#81E052] w-[8px] h-[8px] rounded-full"></div> 
              : <div className="bg-gray w-[8px] h-[8px] rounded-full"></div>
            } 
            <span className="text-[12px] text-black">
              At least 8 characters
            </span>
          </div>
          <div className="flex items-center gap-1">
            {password?.match(/.*[A-Z]/)
              ? <div className="bg-[#81E052] w-[8px] h-[8px] rounded-full"></div>
              : <div className="bg-gray w-[8px] h-[8px] rounded-full"></div> 
            }
            <span className="text-[12px] text-black">
              1 uppercase letter
            </span>
          </div>
        </div>

        <div className="flex sm:flex-row flex-col sm:items-center items-start sm:gap-3 gap-[8px] w-full">
          <div className="flex items-center gap-1">
            {password?.match(/.*\d/)
              ? <div className="bg-[#81E052] w-[8px] h-[8px] rounded-full"></div>
              : <div className="bg-gray w-[8px] h-[8px] rounded-full"></div>
            }
            <span className="text-[12px] text-black">
              1 number
            </span>
          </div>
          <div className="flex items-center gap-1">
            {password?.match(/.*[-/~!#*$@_%+=.,^&(){}[\]|;:<>?\\]/)
              ? <div className="bg-[#81E052] w-[8px] h-[8px] rounded-full"></div>
              : <div className="bg-gray w-[8px] h-[8px] rounded-full"></div>
            }
            <span className="text-[12px] text-black">
              {"1 symbol !,@,#,$,%,^,&,*,(,),<,>,?,/"}
            </span>
          </div>
        </div>
      </div>

      {/* Confirm password */}
      <div className="w-ful my-4">
        <TopPlaceHolderInput
          type="password"
          placeHolder="Confirm password"
          isRequired
          value={""}
          hidePassword
          htmlFor={"ConfirmPassword"}
          onChange={e => setPassword2(e.target.value)}
        />
      </div>

      {/* signup button */}
      <div className="w-full sm:mb-2 mb-4">
        <button type="submit" className="w-full py-[19px] px-4 bg-primary text-white rounded-[4px] text-sm hover:bg-purple-600 hover:text-white">
          Sign up
        </button>
      </div>
      </form>
      {/* trams & conditions */}
      <div className="w-full">
        <p className="w-full text-black text-sm">
          By signing up, you agree you&apos;ve read and accepted our{" "}
          <Link className="underline" to="/terms_conditions">
            Terms and Conditions
          </Link>
          . Please see our{" "}
          <Link className="underline" to="/privacy_policy">
            Privacy Policy
          </Link>{" "}
          for information on how we process your data.
        </p>
      </div>
    </>
  );
};

export default SignUp;
