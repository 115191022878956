/* eslint-disable react/prop-types */
import { useState } from "react";
import { Icons } from "../../../shared/lib/Icon";



function CustomInput({
  placeHolder,
  isRequired = true,
  className,
  id,
  name,
  type = "text",
  onChange,
  placeHolderClass,
  value,
  ...props
}) {
  const [isDisplay, setIsDisplay] = useState(true);
  const [Value, setValue] = useState(value ? value.toString() : "");
  const [eye, setEye] = useState(false);

  const onChangeHandler = (e) => {
    if (typeof onChange === "function") {
      onChange(e, e.target.value);
    }
    setValue(e.target.value);
    if (e.target.value) {
      setIsDisplay(false);
    }

    if (!e.target.value) {
      setIsDisplay(true);
    }
  };

  type = type.toLocaleLowerCase().trim();

  return (
    <div
      className={`w-full relative outline-none border border-[#F3F3F3] bg-white customShadow pr-3  text-black ${className}  ${
        type === "password" ? "flex items-center" : ""
      } `}
    >
      <input
        className={`w-full py-3 px-4 focus:outline-none `}
        type={eye ? "text" : type}
        name={name}
        value={Value}
        id={id}
        required={isRequired}
        // onFocus={onfocusHandler}
        onChange={onChangeHandler}
        {...props}
      />
      {isDisplay && (
        <p
          className={`absolute top-[50%] transform translate-y-[-50%]  left-[1.2rem] text-[#888] select-none pointer-events-none ${placeHolderClass}`}
        >
          {placeHolder} {isRequired && <span className="text-red">*</span>}
        </p>
      )}

      {type === "password" ? (
        <div
          className="cursor-pointer text-xl text-gray "
          onClick={() => setEye(!eye)}
        >
          {eye ? (
            <span className="text-black">{Icons.eye}</span>
          ) : (
            <span>{Icons.eyeHidden}</span>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CustomInput;

export const TopPlaceHolderInput = ({
  type = "text",
  name,
  htmlFor,
  onChange,
  placeHolder = "placeholder",
  isRequired,
  value,
  placeHolderClass,
  isFocus = true,
  hidePassword,
  onFocus,
  ...props
}) => {
  const [Value, setValue] = useState(value ? value.toString() : "");
  const [eye, setEye] = useState(false);
  const [isFocused, setIsFocused] = useState(Value ? true : false);

  const onChangeHandle = (e) => {
    if (!e.target.value) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
    if (typeof onChange === "function") {
      onChange(e, e.target.value);
    }
    setValue(e.target.value);
  };

  type = type.toLocaleLowerCase().trim();

  const onFocusHandler = (e) => {
    setIsFocused(true);
    if(typeof onFocus === 'function'){
      onFocus(e)
    }
  };

  const onFocusOutHandler = (e) => {
    if (!e.target.value) {
      setIsFocused(false);
    }
  };

  return (
    <div
      className={`bg-white  px-3 py-4 customShadow border  ${
        isFocused && isFocus ? "border-primary" : "border-[#FDFDFD]"
      } rounded-md `}
    >
      <div
        className={`${
          type === "password" ? "flex items-center" : ""
        } cursor-text`}
      >
        <div className="w-full   relative ">
          <label
            htmlFor={htmlFor}
            className={`block absolute select-none transform cursor-text  transition-all duration-400  ${
              isFocused || type == "date"
                ? "text-[0.8rem] top-[-16px] translate-y-[0]"
                : "top-[50%]  translate-y-[-50%]  text-[1rem]"
            } left-0 text-[#A5A5A5] ${placeHolderClass}`}
          >
            {placeHolder}
            {isRequired && <span className="text-red">*</span>}
          </label>
          <input
            type={eye ? "text" : type}
            id={htmlFor}
            value={Value}
            className={`w-full h-full  border-none focus:outline-0 mt-1`}
            onChange={onChangeHandle}
            name={name}
            onFocus={onFocusHandler}
            onBlur={onFocusOutHandler}
            required={isRequired}
            {...props}
          />
        </div>

        {type === "password" && !hidePassword ? (
          <div
            className="cursor-pointer text-xl text-gray "
            onClick={() => setEye(!eye)}
          >
            {eye ? (
              <span className="text-black">{Icons.eye}</span>
            ) : (
              <span>{Icons.eyeHidden}</span>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
