/* eslint-disable react/prop-types */
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import { Photo, logo, sneaker_8, sneaker_bw } from "../../../shared/images";
import "./Home.css";
// eslint-disable-next-line no-unused-vars
const productData = [
  {
    id: 1,
    owner: "Pat Franecki",
    auth_data: "Dec 16 2021",
    condition: "New",
    image: sneaker_bw,
  },
  {
    id: 2,
    owner: "Pat Franecki",
    auth_data: "Dec 16 2021",
    condition: "New",
    image: sneaker_bw,
  },
  {
    id: 3,
    owner: "Pat Franecki",
    auth_data: "Dec 16 2021",
    condition: "New",
    image: sneaker_bw,
  },
  {
    id: 4,
    owner: "Pat Franecki",
    auth_data: "Dec 16 2021",
    condition: "New",
    image: sneaker_bw,
  },
  {
    id: 5,
    owner: "Pat Franecki",
    auth_data: "Dec 16 2021",
    condition: "New",
    image: sneaker_8,
  },
  {
    id: 6,
    owner: "Pat Franecki",
    auth_data: "Dec 16 2021",
    condition: "New",
    image: sneaker_bw,
  },
];

// eslint-disable-next-line no-unused-vars
const ProductCard = ({ product }) => {
  return (
    <div className={`bg-[#FAFAFA] py-3 px-3  customShadow rounded-md `}>
      {/* wrapper  */}
      <div>
        <div className="">
          {/* logo  */}
          <figure className="my-4 w-[102px] mx-auto">
            <img src={logo} alt="logo" className="w-full h-full" />
          </figure>

          <div className="flex justify-center">
            <figure
              className=" block md:w-[190px] md:h-[160px] !cursor-pointer "
              data-product="product-image"
            >
              <img
                src={sneaker_8}
                className="w-full h-full z-10  !cursor-pointer"
              />
            </figure>
          </div>

          {/* owner  */}

          <div className=" relative top-[-40px] ">
            <div className="mb-4">
              <figure className="w-[50px]  h-[50px] overflow-hidden rounded-full outline outline-primary outline-1 outline-offset-1">
                <img src={Photo} alt="avatar" className="w-full h-full" />
              </figure>
            </div>

            {/* owner name  */}
            <p className="font-bold mt-1">
              <span>Owner: </span>
              <br />
              <span className="text-primary"> Pat Franecki</span>
            </p>
            {/* auth name  */}
            <p className="font-bold">
              <span>Auth Date: </span>
              <br />
              <span className="text-primary"> Dec 16, 2021</span>
            </p>
            {/* auth name  */}
            <p className="font-bold">
              <span>Condition: </span>
              <br />
              <span className="text-primary"> New</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function Home() {
  const productShowCaseRef = useRef(null);

  // useEffect(() => {
  //   initialActiveCardShow();
  // }, [productShowCaseRef]);

  // function initialActiveCardShow() {
  //   if (productShowCaseRef?.current) {
  //     const productsWrapper = productShowCaseRef.current.querySelectorAll(
  //       '[data-wrapper="product_showcase_wrapper"]'
  //     );

  //     productsWrapper.forEach((ele) => {
  //       if (ele) {
  //         const productCard = ele.querySelector(
  //           '[data-product="product-card"]'
  //         );
  //         productCard.style.display = "none";
  //         const initialShowEle =
  //           productsWrapper[productsWrapper.length - 1 - 1];

  //         const initialShowProduct = initialShowEle.querySelector(
  //           '[data-product="product-card"]'
  //         );
  //         const initialShowImage = initialShowEle.querySelector(
  //           '[data-product="product-image"]'
  //         );
  //         initialShowImage.style.display = "none";
  //         initialShowImage.classList.add("inactive-image");
  //         initialShowProduct.style.display = "block";
  //         initialShowProduct.classList.add("active-card");
  //       }
  //     });
  //   }
  // }

  // mouse over handler
  // const mouseOverHandle = (e) => {
  //   const productCard = e.currentTarget.parentNode.nextElementSibling;

  //   e.currentTarget.parentNode.style.display = "none";
  //   const activeProduct = document.querySelector(
  //     ".product_show_case .active-card"
  //   );
  //   const inactiveImage = document.querySelector(
  //     ".product_show_case .inactive-image"
  //   );

  //   if (activeProduct && inactiveImage) {
  //     activeProduct.classList.remove("active-card");
  //     inactiveImage.classList.remove("inactive-image");
  //     activeProduct.style.display = "none";
  //     inactiveImage.style.display = "block";
  //   }
  //   productCard.style.display = "block";
  //   productCard.classList.add("active-card");
  //   e.currentTarget.parentNode.classList.add("inactive-image");
  // };

  return (
    <div className="md:py-[102px] py-[40px]">
      <div className="">
        {/* product image show case for desktop  */}
        <div className="md:block hidden">
          <div className=" product_show_case" ref={productShowCaseRef}>
            {/* sneaker image  */}
            <div
              className=" product_showcase_wrapper relative   "
              data-wrapper="product_showcase_wrapper"
            >
              <figure
                className="showcase_image md:w-[150px] sm:w-[100px] md:h-[160px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>

            {/* sneaker image  */}
            <div
              className=" product_showcase_wrapper relative   "
              data-wrapper="product_showcase_wrapper"
            >
              <figure
                className="showcase_image md:w-[150px] sm:w-[100px] md:h-[160px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>

            {/* sneaker image  */}
            <div
              className=" product_showcase_wrapper relative   "
              data-wrapper="product_showcase_wrapper"
            >
              <figure
                className="showcase_image md:w-[150px] sm:w-[100px] md:h-[160px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>

            {/* sneaker image  */}
            <div
              className=" product_showcase_wrapper relative   "
              data-wrapper="product_showcase_wrapper"
            >
              <figure
                className="showcase_image md:w-[150px] sm:w-[100px] md:h-[160px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>

            {/* product card  */}
            <div
              className="product_card min-w-[180px]  z-10 "
              data-product="product-card"
            >
              <NavLink className={"block"} to={"/"}>
                <ProductCard product={{ image: sneaker_8 }} />
              </NavLink>
            </div>

            {/* sneaker image  */}
            <div
              className=" product_showcase_wrapper relative   ms-auto  "
              data-wrapper="product_showcase_wrapper"
            >
              <figure
                className="showcase_image md:w-[150px] sm:w-[100px] md:h-[160px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>
          </div>

          {/* heading  */}

          <div className=" hidden md:flex justify-between  items-center  lg:static md:relative md:top-[-60px] py-6 md:top-auto">
            <div>
              <h1 className="landing_heading">
                Keep your sneaker game{" "}
                <span className="text-primary">REAL.</span>
              </h1>

              {/* sign up link  */}
              <NavLink
                className="py-[19px] px-[16px] bg-primary rounded-md text-white block w-fit mt-[40px] text-[14px]"
                to="/auth"
              >
                Sign up Now
              </NavLink>

            </div>

            <div className=" md:self-end relative md:top-[60px] md:left-[-30px] border-l-[2px] pl-[17px] py-[10px] border-gray">
              <p className="landing_para">
                Seamlessly transfer ownership. Buy with peace of mind. Resell
                with ease. One-time legit-check fee.
              </p>
            </div>
          </div>
        </div>



        {/* product image show case page for mobile  */}

        <div className="md:hidden block">
          {/* heading   */}
          <div>
            <h1 className="landing_heading_sm ">
              Keep your sneaker game <span className="text-primary">REAL.</span>
            </h1>
          </div>

          {/* all product image  */}

          <div className="product_showcase_wrapper__sm_container ">
            {/* sneaker image  */}
            <div
              className=" product_showcase_wrapper_sm relative   "
              data-wrapper="product_showcase_wrapper_sm"
            >
              <figure
                className="showcase_image_sm sm:w-[150px] sm:h-[160px] w-[124px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>

            <div
              className=" product_showcase_wrapper_sm relative   "
              data-wrapper="product_showcase_wrapper_sm"
            >
              <figure
                className="showcase_image_sm sm:w-[150px] sm:h-[160px] w-[124px] !cursor-pointer "
                data-product="product-image-sm"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>

            <div
              className=" product_showcase_wrapper_sm relative   "
              data-wrapper="product_showcase_wrapper_sm"
            >
              <figure
                className="showcase_image_sm sm:w-[150px] sm:h-[160px] w-[124px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>
            <div
              className=" product_showcase_wrapper_sm relative   "
              data-wrapper="product_showcase_wrapper_sm"
            >
              <figure
                className="showcase_image_sm sm:w-[150px] sm:h-[160px] w-[124px] !cursor-pointer "
                data-product="product-image"
              >
                <img
                  src={sneaker_bw}
                  className="w-full h-full z-10  !cursor-pointer"
                />
              </figure>
            </div>
          </div>

          {/* product card for mobile device  */}

          <div className="flex  justify-around gap-2 bg-white shadow-md rounded-md py-5 px-4">
            <div className="flex  flex-col justify-center items-center">
              <img src={logo} alt="logo" className="w-[67px]  " />
              {/* product imag  */}
              <NavLink className={"block"} to={"/product_page"}>
                <figure className="max-w-[124px] sm:max-w-[180px] h-auto my-5">
                  <img src={sneaker_8} />
                </figure>
              </NavLink>
              {/* info  */}

              <div className="md:hidden mt-[-60px]">
                <p className="font-bold">
                  <span>Auth Date:</span>
                  <br />
                  <span className="text-primary">Dec 16, 2021</span>
                </p>
              </div>
            </div>

            {/* owner and condition info  */}
            <div className="flex flex-col gap-1  justify-between ">
              <div>
                <figure className="w-[50px]  h-[50px] overflow-hidden rounded-full outline outline-primary outline-1 outline-offset-1">
                  <img src={Photo} alt="avatar" className="w-full h-full" />
                </figure>
                {/* owner name  */}
                <p className="font-bold mt-1">
                  <span>Owner: </span>
                  <br />
                  <span className="text-primary"> Pat Franecki</span>
                </p>
              </div>
              {/* condition  */}
              <div>
                {/* auth name  */}
                <p className="font-bold">
                  <span>Condition: </span>
                  <br />
                  <span className="text-primary"> New</span>
                </p>
              </div>
            </div>
          </div>
          {/* description  */}
          <div className="my-6">
            <div className=" border-l border-gray pl-3">
              <p className="landing_para_sm">
                Seamlessly transfer ownership. Buy with peace of mind. Resell
                with ease. One-time legit-check fee.
              </p>
            </div>

            <NavLink
              className={
                "py-[19px] px-[16px] bg-primary rounded-md text-white block w-fit mt-[40px]  "
              }
              to={"/auth"}
            >
              Sign up Now
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
