/* eslint-disable no-unused-vars */
import React, { useState } from "react";
//
import { Link, useNavigate, useLocation } from "react-router-dom";
import Brand from "../../assets/vector-1@2x.png";
import { TopPlaceHolderInput } from "../../components/widgets/customInput/CustomInput";
import { setNewPasswordWithCode } from "../../apis/auth/authAPI";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [eye, setEye] = useState(false);
  const [confirmeye, setConfirmeye] = useState(false);

  const email = location.state;
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  function handleSubmit(e) {
    e.preventDefault();
    setNewPasswordWithCode(email, code, newPassword)
    .then(async () => {
      navigate('/auth/success');
    }).catch(err => {
      // display error
      console.log(err);
    });
  }

  return (
    <section className="w-full">
      <div className="costomContainer">
        <div className="flex flex-col w-full lg:gap-[80px] md:gap-[60px] sm:gap-[50px] gap-[40px] justify-center">
          <div className="flex justify-center items-center pt-6">
            <img className="sm:h-[32px] h-[24px]" src={Brand} alt="Brand" />
          </div>
          {/* login & signup body */}
          <div className="sm:w-[364px] w-full mx-auto shadow p-6 rounded-md bg-white border border-[#F3F3F3]">
            <div className="w-full flex flex-col gap-[24px] text-center">
              <div className="w-full">
                <h2 className="md:text-[32px] sm:text-[28px] text-[24px] font-mono font-bold text-black">
                  Set new password
                </h2>
              </div>
              {/* reset code */}
              <div className="w-full">
                <TopPlaceHolderInput
                  type="text"
                  placeHolder="Password reset code"
                  isRequired
                  value={""}
                  htmlFor={"code"}
                  onChange={e => setCode(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col">
                {/* password */}
                <div className="w-full">
                  <TopPlaceHolderInput
                    type="password"
                    placeHolder="Password"
                    isRequired
                    value={""}
                    htmlFor={"password"}
                    onChange={e => setNewPassword(e.target.value)}
                  />
                </div>

                {/* symbol */}
                <div className="w-full flex flex-col sm:gap-0 gap-[8px] mt-2">
                  <div className="flex sm:flex-row flex-col sm:items-center items-start sm:gap-3 gap-[8px] w-full">
                    <div className="flex items-center gap-1">
                      <div className="bg-gray w-[8px] h-[8px] rounded-full"></div>
                      <span className="text-[12px] text-black">
                        At least 8 characters
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-[#81E052] w-[8px] h-[8px] rounded-full"></div>
                      <span className="text-[12px] text-black">
                        1 uppercase letter
                      </span>
                    </div>
                  </div>

                  <div className="flex sm:flex-row flex-col sm:items-center items-start sm:gap-3 gap-[8px] w-full">
                    <div className="flex items-center gap-1">
                      <div className="bg-gray w-[8px] h-[8px] rounded-full"></div>
                      <span className="text-[12px] text-black">1 number</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-[#81E052] w-[8px] h-[8px] rounded-full"></div>
                      <span className="text-[12px] text-black">
                        {"1 symbol !,@,#,$,%,^,&,*,(,),<,>,?,/"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Confirm password */}
              <div className="w-full">
                <TopPlaceHolderInput
                  type="password"
                  hidePassword
                  isRequired
                  value={""}
                  htmlFor={"confirmPassword"}
                  placeHolder="Confirm password"
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
              {/* signup button */}
              <div className="w-full">
                <button
                  onClick={handleSubmit}
                  className="w-full py-[19px] px-4 bg-primary text-white rounded-[4px] hover:bg-purple-600 text-sm hover:text-white"
                >
                  Confirm
                </button>
              </div>

              <div className="w-full text-center">
                <Link className="text-sm text-primary" to="/auth">
                  Go to login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SetNewPassword;
