/* eslint-disable no-unused-vars */
import React from "react";
//
import Brand from "../../assets/vector-1@2x.png";
import Success from "../../assets/success.png";
import { Link, useNavigate } from "react-router-dom";

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <section className="w-full">
      <div className="costomContainer">
        <div className="flex flex-col w-full lg:gap-[80px] md:gap-[60px] sm:gap-[50px] gap-[40px] justify-center">
          <div className="flex justify-center items-center pt-6">
            <img className="sm:h-[32px] h-[24px]" src={Brand} alt="Brand" />
          </div>
          {/* login & signup body */}
          <div className="sm:w-[362px] w-full mx-auto shadow p-6 rounded-md bg-[#FDFDFD] border border-[#F3F3F3]">
            <div className="w-full flex flex-col gap-[24px] text-center">
              <div className="w-full flex justify-center items-center">
                <img className="h-[80px]" src={Success} alt="Success" />
              </div>
              <div className="w-full">
                <p className="sm:text-[16px] text-sm text-[#545454] tracking-[0.5px] leading-[150%]">
                  Your password has been reset successfully.
                </p>
              </div>
              {/* signup button */}
              <div className="w-full">
                <button
                  onClick={() => navigate("/")}
                  className="w-full py-[19px] px-4 bg-primary text-white rounded-[4px] hover:bg-purple-600 text-sm hover:text-white"
                >
                  Go to homepage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessMessage;
