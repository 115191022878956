import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductAPI } from "../../../apis/product/productAPI";
import { CopyLink } from "../../../apis/product/copyLink";
import { getTokenByName } from "../../../apis/auth/authAPI";
// import { sneaker_8 } from '../../../shared/images';
import Modal from "../../../components/ul/modal/Modal";
import TransferOwner from "../../../components/dashboard/table/TransferOwner";
import { Avatar } from "../../../shared/images";

const SingleProductPage = () => {
  const { productId } = useParams();

  const [item, setItem] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [showTransfer, setShowTransfer] = useState(false);

  const [image, setImage] = useState("http://localhost:5173/src/assets/global/image@2x.png");
  const changeImage = (e) => {
    setImage(e.target.src);
  };

  useEffect(() => {
    getProductById(productId);
    setImage(item?.image_url);
  }, []);

  const showTransferButton = (owner_id) => {
    const userName = getTokenByName('userName');
    if (owner_id == userName) {
      setShowTransfer(true);
    }
  }

  const getProductById = async (id) => {
    const data = await ProductAPI.getById(id);
    setItem(data);
    showTransferButton(data?.owner_id);
  }

  const modalShowHandler = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  }

  return (
    <div className="md:py-[56px] py-[40px]">
      <h1 className="heading my-6">{item?.brand} {item?.title}</h1>

      <div className=" flex gap-4 flex-col md:flex-row  w-full">
        {/* Image section */}
        <div className=" flex flex-col lg:flex-row gap-4 ">
          {/* Side images */}
          <div className=" gap-4 flex flex-row flex-wrap lg:flex-col order-2 lg:order-1 ">
            <div>
              <div className="w-[73px] p-2   customShadow cursor-pointer">
                <img
                  onClick={changeImage}
                  className="w-full"
                  src={item?.image_url}
                  alt="..."
                />
              </div>
            </div>
            <div>
              <div className="w-[73px] p-2  customShadow cursor-pointer">
                <img
                  onClick={changeImage}
                  className="w-full "
                  src={item?.tag_image_url}
                  alt="..."
                />
              </div>
            </div>
          </div>
          {/* Main image */}
          <div className=" w-full md:w-[346px] order-1 lg:order-2 customShadow p-4">
            <img className="w-full h-full" src={`${image || item?.image_url}`} alt="..." />
          </div>
        </div>
        {/* Content section */}
        <div className=" customShadow p-6 w-full md:w-7/12	">
          <div className="single_product_user_information">
            <h5 className="font-bold">{item?.gender}</h5>
            <ul>
              <li>
                Size:<span className="font-bold ml-2">{item?.size}</span>
              </li>
              <li>
                Condition:<span className="font-bold ml-2">{item?.condition}</span>
              </li>
              <li>
                Authentication date:
                <span className="font-bold ml-0 sm:ml-2 block sm:inline-block">
                  {
                    item?.auth_date 
                    ? new Date(item.auth_date).toLocaleDateString('en-US', {
                        year: 'numeric', 
                        month: 'long', 
                        day: 'numeric'
                      })
                    : 'No Date'
                  }
                </span>
              </li>
              <li className="flex align-center flex-wrap">
                Owned by:
                <span className="font-bold flex flex-wrap gap-2">
                  {" "}
                  <span className="ml-0 sm:ml-2 sm:inline-block w-[25px] block">
                    <img
                      className="w-[100%]"
                      src={Avatar}
                      alt="..."
                    />
                  </span>{" "}
                  {item?.owner_id}
                </span>
              </li>
            </ul>
          </div>
          {/* Rating */}
          <div className=" py-4 my-4 border-y-2  border-[#f3f3f3]">
            <p>
            {/* TO DO */}
              {/* <span className="font-bold text-2xl common-color">203</span> out
              of <span className="font-bold">400</span> ”Jordan Retro 1 High”
              sneakers authenticated worldwide */}
              {item?.note}
            </p>
          </div>
          {/* button */}
          <div className=" flex lg:flex-row flex-col gap-4 my-4">
            {showTransfer &&
              <button
                className="py-3 px-4 bg-primary text-white rounded-[4px] hover:bg-purple-600 hover:text-white text-sm"
                onClick={modalShowHandler}
              >
                Transfer ownership
              </button>
            }
            <button
              className="py-3 px-8 border-2 border-[#A33AFF] common-color text-white rounded-[4px] hover:bg-primary hover:text-white text-sm"
              onClick={e => CopyLink(item?.id)}
            >
              Copy link
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={modalShowHandler}
        title={"Transfer item ownership"}
        style={{ maxWidth: "368px", background: "#fff"}}
        id={"table-modal"}
      >
        <TransferOwner
          data={item}
          onHide={modalShowHandler}
        />
      </Modal>
    </div>
  );
};

export default SingleProductPage;
