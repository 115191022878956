import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Table from "../../../components/dashboard/table/Table";
import { rows } from "../../../components/dashboard/tableData"; 
import Dropdown from "../../../components/ul/dropdown/Dropdown";
import Filter from "../../../components/ul/filter/Filter";
import Pagination from "../../../components/ul/pagination/Pagination";
import FilterButton from "../../../components/widgets/filterButton/FilterButton";
import SearchBar from "../../../components/widgets/search/SearchBar";
import { Icons } from "../../../shared/lib/Icon";
import styles from "./Dashboard.module.css";
import useToast from "../../../hooks/useToast";
import { ProductAPI } from "../../../apis/product/productAPI";
import { getTokenByName } from "../../../apis/auth/authAPI";
import { getToken } from "../../../apis/auth/authAPI";


const Dashboard = () => {
  const [isClear, setIsClear] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [currentPage , setCurrentPage] = useState(1)
  const dropdownTargetRef = useRef(null);
  const [targeted, setTarget] = useState(null);
  const [activeTab , setActiveTab] = useState('products')
  const {showToast , Toast} = useToast()

  const [rows, setRows] = useState([]);
  const [originals, setOriginals] = useState([]);
  const { state } = useLocation();
  // display add item button for authenticated users
  const [isAuthenticated, setIsAuthenticated] =useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);


  

  

  useEffect(() => {
    // if (location?.pathname === "/home") {
    if (!getToken()) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [location]);


  useEffect(() => {
    getProductsByActiveTab(activeTab);
  }, []);

  const getProductsByActiveTab = (activeTab) => {
    setIsAuthenticated(getTokenByName('authorized_store'));
    if (activeTab === 'inventory') {
      productsByOwner();
    } else {
      products();
    }
  }

  const products = async () => {
    const data = await ProductAPI.getAll();
    setRows(data);
    setOriginals(data);
  }

  const productsByOwner = async () => {
    const userName = getTokenByName('userName');
    const data = await ProductAPI.getByOwnerId(userName);
    setRows(data);
    setOriginals(data);
  }

  const searchBarHandleChange = (keyword) => {
    if (originals?.length > 0) {
      setRows(originals.filter(row => row.title.toLowerCase().includes(keyword.toLowerCase())));
    }
  }

  

  // TO-DO: combine with keyword
  // TO-DO: priority alphabet -> auth date -> condition
  const FilterChange = (filter) => {
    // Clone originals before any operation to avoid mutation
    let updatedRows = [...originals];
  
    // sort by
    if (filter === "az") {
      updatedRows.sort((a, b) => a.title.localeCompare(b.title));
    } else if (filter === "za") {
      updatedRows.sort((a, b) => -1 * a.title.localeCompare(b.title));
    }

    if (filter === "no") {
      updatedRows.sort((a, b) => new Date(b.auth_date) - new Date(a.auth_date));
    } else if (filter === "on") {
        updatedRows.sort((a, b) => new Date(a.auth_date) - new Date(b.auth_date));
    }
  

  
    const conditionOptions = ["Deadstock/New", "Excellent", "Good", "Fair", "Worn", "Poor"];
    console.log(filter)
    if (conditionOptions.includes(filter)) {
      setFilterHistory(prevHistory => [...prevHistory, filter]);
      updatedRows = updatedRows.filter(row => row.condition === filter);
    }

  
    // Update the state with the new array
    setRows(updatedRows);
  }

  
  
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // const DateFilterChange = (date) => {
  //   if (fromDate && !toDate) {
  //     const sorted = originals.filter(row => new Date(row.auth_date) >= fromDate);
  //     setRows(sorted);
  //   }
  //   if (!fromDate && toDate) {
  //     const sorted = originals.filter(row => new Date(row.auth_date) <= toDate);
  //     setRows(sorted);
  //   }
  //   if (fromDate && toDate) {
  //     const sorted = originals.filter(row => new Date(row.auth_date) >= fromDate && new Date(row.auth_date) <= toDate);
  //     setRows(sorted);
  //   }
  // }

  const perPage = 6;
  // dropdown show handler

  // const token = getToken();
  const [toastMessage, setToastMessage] = useState('');
  const getMessage = (message) => {
    setToastMessage(message);
  }
  useEffect(()=>{
    if (state?.message || toastMessage) {
      showToast(state?.message || toastMessage);
      window.history.replaceState({}, document.title);
    }
  },[]);

  useEffect(() => {
    if (isClear) {
      setRows(originals); // Reset rows to originals
      // Reset any other filter-related states here
      setIsClear(false); // Reset isClear to allow for future clearing actions
    }
  }, [isClear, originals]);

  



  useEffect(() => {
    if (dropdownTargetRef?.current) {
      setTarget(dropdownTargetRef?.current);
    }
  }, [dropdownTargetRef]);

   const dropDownShowHandler = (e) => {
    e.stopPropagation();
    setShowDropDown(!showDropDown);
    setIsClear(false); // Ensure isClear is reset when dropdown is toggled
  };



  return (
    
    <div  className="py-[40px] md:py-[56px]">
      
      <h1 className="heading ">Dashboard</h1>
      <div
        className={`${styles.align_items} lg:flex  justify-between items-center w-full `}
      >
        <div>
            {isLoggedIn ? (
              <div className=" flex items-center gap-[50px] ">
              <button
                type="button"
                className={` pb-2 ${
                  activeTab === "inventory" && "border-b-[2px]  border-primary text-primary"
                } `}
                onClick={() => {
                  setActiveTab("inventory");
                  getProductsByActiveTab("inventory");
                }}
              >
                <span className="px-3"> My Inventory</span>
              </button>
              <button
                type="button"
                className={`${styles.text} pb-2 ${
                  activeTab === "products" && "border-b-[2px]  border-primary text-primary"
                } `}
                onClick={() => {
                  setActiveTab("products");
                  getProductsByActiveTab("products");
                }}
              >
                All Products
              </button>
            </div>
              

            ) : <div className=" flex items-center  ">
            <button
              type="button"
              className={`${styles.text} pb-2 ${
                activeTab === "products" && "border-b-[2px]  border-primary text-primary"
              } `}
              onClick={() => {
                setActiveTab("products");
                getProductsByActiveTab("products");
              }}
            >
              All Products
            </button>
          </div>}
          </div>
        

        <div className="flex flex-1  lg:justify-end items-center gap-[0.8rem] mt-6 lg:mt-0">
          <div>
            <SearchBar onChange={searchBarHandleChange} />
          </div>

          <div ref={dropdownTargetRef}>
            <FilterButton
              isInnerHtmlOnMobile={true}
              onClick={(e) => {
                dropDownShowHandler(e);
              }}
            >
              Filter
            </FilterButton>
          </div>
          {isAuthenticated &&
            <div className="px-[12px] py-[12px]  rounded-md customShadow bg-primary text-white ">
              <NavLink
                to={"/add-item"}
                className={"   flex justify-center items-center gap-[8px] "}
              >
                <span>{Icons?.plus}</span>
                <span className="hidden sm:block"> Add Item</span>
              </NavLink>
            </div>
          }
          {/* dropdown for filter button  */}
          <Dropdown
            show={showDropDown}
            onHide={dropDownShowHandler}
            isBodyClickToClose = {true}
            wrapperClasses={"  mt-2  "}
            targetEle={targeted}
            headerComponent={
              <button
                type="reset"
                form="sort_form"
                className="text-end w-full small-font "
                onClick={() => {
                  setIsClear(true);
                }}
              >
                Clear all
              </button>
            }
          >
            <Filter
              onClear={(bool) => {
                setIsClear(bool);
              }}
              isClear={isClear}
              onChange={FilterChange}
              // onFromDateChange={e => 
              //   {
              //     setFromDate(e);
              //     DateFilterChange(e);
              //   }}
              // onToDateChange={e => 
              //   {
              //     setToDate(e);
              //     DateFilterChange(e);
              //   }}
            />
          </Dropdown>
        </div>
      </div>

      <div>
        <div className="">
          <Table
            data={rows?.slice(
              (currentPage - 1) * perPage,
              currentPage * perPage
            )}
            isProducts = {activeTab === 'products'}
            isInventory = {activeTab === 'inventory'}
            getMessage = {getMessage}
          />
        </div>
        <div className="my-3">
          <Pagination
            len={rows?.length}
            perPage={perPage}
            maxEllipsis={4}
            currentPage={currentPage}
            currentPageHandler={setCurrentPage}
          />
        </div>
      </div>


      <Toast/>
    </div>
  );
};

export default Dashboard;
