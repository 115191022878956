/* eslint-disable react/prop-types */





function TableHeader({ data , className}) {
  return (
    <>
      {data?.map((head) => (
        <th key={head?.id} className={`${className}`}>
          {head?.text}
        </th>
      ))}

      <th className="lg:hidden sticky top-0   right-[-1px] !w-[40px]  !p-0  sticky-th bg-white "> </th>
    </>
  );
}

export default TableHeader