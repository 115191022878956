import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";

const Checkbox = ({ onChange, name, children, isReset, id }) => {
  const [checked, setChecked] = useState(false);

  const changeHandler = (e) => {
    setChecked(e.target.checked);
    if (typeof onChange === "function") {
      onChange(e);
    }
  };

  useEffect(() => {
    if (isReset) {
      const checkbox = document.querySelectorAll(`label.custom_checkbox`);
      if (checkbox) {
        checkbox.forEach((ele) => {
          const input = ele.querySelector("input");
          input.checked = false;
          setChecked(false);
        });
      }
    }
  }, [isReset, id, name]);

  return (
    <>
      <label
        className={"flex items-center  select-none custom_checkbox"}
        htmlFor={name}
        id={id}
      >
        <div
          className={`w-[16px] h-[16px] rounded-[4px] ${
            checked ? "border-none bg-primary" : "border border-gray bg-none"
          } flex justify-center items-center mr-2 `}
        >
          {checked ? (
            <span className="text-white">
              <FiCheck />
            </span>
          ) : (
            ""
          )}
        </div>

        <input
          type="checkbox"
          className="hidden"
          onChange={changeHandler}
          name={name}
          id={name}
        />
        <div className="extra-small-font text-secondary">{children}</div>
      </label>
    </>
  );
};

Checkbox.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  children: propTypes.any,
  isReset: propTypes.bool,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default Checkbox;
