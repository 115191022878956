import React, { useEffect, useRef } from "react";

const Dropdown = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ children, show, onHide, isBodyClickToClose = true, targetEle }) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
      const hiddenHandler = function (e) {
        if (
          isBodyClickToClose &&
          typeof onHide == "function" &&
          dropdownRef?.current &&
          !dropdownRef?.current?.contains(e.target)
        ) {
          onHide(e);
        }
      };

      if (show) {
        window.addEventListener("click", hiddenHandler, true);
      } else {
        window.removeEventListener("click", hiddenHandler);
      }

      return () => {
        window.removeEventListener("click", hiddenHandler);
      };
    }, [show, onHide, isBodyClickToClose]);

    if (!show && targetEle) {
      return;
    }

    return (
      <>
        <div
          className={`min-w-[250px] transfer-owner-popper td-drop min-h-fit py-2 px-3 rounded-md bg-white shadow-xl absolute transform translate-x-[-200px] top-0  z-[9999]  left-[-60px] backdrop-blur-[5px] ${
            show ? "block" : "hidden"
          }`}
          ref={dropdownRef}
        >
          {/* button click dropdown popper  */}
          {children}
        </div>
      </>
    );
  }
);

Dropdown.displayName = "Dropdown";
export default Dropdown;
