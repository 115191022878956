/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { resetPassword } from "../../apis/auth/authAPI";
import { Link, useNavigate } from "react-router-dom";
import Brand from "../../assets/vector-1@2x.png";
import { TopPlaceHolderInput } from "../../components/widgets/customInput/CustomInput";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    resetPassword(email)
    navigate("/auth/new-password", { state: email });
  }

  return (
    <section className="w-full">
      <div className="costomContainer">
        <div className="flex flex-col w-full lg:gap-[80px] md:gap-[60px] sm:gap-[50px] gap-[40px] justify-center">
          <div className="flex justify-center items-center pt-6">
            <img className="sm:h-[32px] h-[24px]" src={Brand} alt="Brand" />
          </div>
          {/* login & signup body */}
          <div className="sm:w-[362px] w-full mx-auto shadow p-6 rounded-md bg-white border border-[#F3F3F3]">
            <div className="w-full flex flex-col gap-[24px] text-center">
              <div className="w-full">
                <h2 className="md:text-[32px] sm:text-[28px] text-[24px] font-mono font-bold text-black">
                  Reset password
                </h2>
              </div>
              <div className="w-full">
                <p className="sm:text-[16px] text-sm text-[#545454]">
                  Enter your email to receive password reset instructions
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                {/* email */}
                <div className="w-full">
                  <TopPlaceHolderInput
                    type="email"
                    name={"email"}
                    htmlFor={"email"}
                    placeHolder="Email address"
                    isRequired
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                {/* signup button */}
                <div className="w-full">
                  <button
                    type="submit"
                    className="w-full py-[19px] px-4 bg-primary text-white rounded-[4px] hover:bg-purple-600 text-sm hover:text-white"
                  >
                    Reset password
                  </button>
                </div>
              </form>
              <div className="w-full text-center">
                <Link className="text-sm text-primary" to="/auth">
                  Back to log-in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
