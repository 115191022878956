export const ProductAPI = {
  create: function (data) {
    console.log("Product API data:", data);
    return fetch(import.meta.env.VITE_AWS_PRODUCT_API, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      // credentials: 'include',
      mode: "no-cors"
    })
    .then(response => response.json())
    .catch(e => {
      console.log(e);
      return e;
    });
  },
  getAll: async function () {
    try {
      const response = await fetch(import.meta.env.VITE_AWS_PRODUCT_API);
      const data = await response.json();
      return data;
    } catch(err) {
      console.log(err);
    }
  },
  getById: async function (id) {
    const response = await fetch(`${import.meta.env.VITE_AWS_PRODUCT_API}/${id}`);
    const data = await response.json();
    return data[0];
  },
  getByOwnerId: async function (owner_id) {
    try {
      const response = await fetch(`${import.meta.env.VITE_AWS_OWNER_API}/${owner_id}`);
      const data = await response.json();
      return data;
    } catch(err) {
      console.log(err);
    }
  },
  edit: function (data) {
    fetch(import.meta.env.VITE_AWS_PRODUCT_API, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      },
      mode: "cors"
    })
    .catch(e => {
      console.log(e);
      return e;
    })
  }
  // get: async function (id, cancel = false) {
  //   const response = await api.request({
  //     url: `/products/:${id}`,
  //     method: "GET",
  //     signal: cancel? cancelApiObject[this.get.name].handelRequestCancellation().signal: undefined,
  //   })
  //   return response.data.product
  // },
  // search: async function (name, cancel = false) {
  //   const respone = await api.request({
  //     url: "/products/search",
  //     method: "GET",
  //     params: {
  //       name: name,
  //     },
  //     signal: cancel? cancelApiObject[this.search.name].handelRequestCancellation().signal: undefined,
  //   })
  //   return respone.dadta.products
  // },
  // create: async function (product, cancel = false) {
  //   await api.request({
  //     url: `/products`,
  //     method: "POST",
  //     data: product,
  //     signal: cancel? cancelApiObject[this.create.name].handelRequestCancellation().signal: undefined,
  //   })
  // },
}