/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

function Dropdown({
  headerComponent,
  headerText,
  show,
  onHide,
  children,
  isBodyClickToClose = false,
  wrapperClasses,
  // eslint-disable-next-line react/prop-types
  targetEle,
  placement = "left",
  // eslint-disable-next-line no-unused-vars
  ignoreToCloseEleId,
}) {
  const dropdownRef = useRef(null);
  const [place, setPlace] = useState({
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
  });
  // const [translate, setTranslate] = useState({ translateX: 0, translateY: 0 });
  // eslint-disable-next-line no-unused-vars
  const [Placement, setPlaceMent] = useState(placement);

  // window or body click to close

  useEffect(() => {
    const hiddenHandler = function (e) {
      if (
        isBodyClickToClose &&
        typeof onHide == "function" &&
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(e.target)
      ) {
        onHide(e);
      }
    };

    if (show) {
      window.addEventListener("click", hiddenHandler, true);
    } else {
      window.removeEventListener("click", hiddenHandler);
    }

    return () => {
      window.removeEventListener("click", hiddenHandler);
    };
  }, [show, onHide, isBodyClickToClose]);

  // placement
  useEffect(() => {
    let handlePlacement;
    if (targetEle && dropdownRef?.current && show) {
      targetEle.style.position = "relative";
      let top,
        left,
        right,
        bottom = "auto";
      // let translateX,
      //   translateY = 0;

      handlePlacement = () => {
        const targetRect = targetEle.getBoundingClientRect();
        const dropDownRect = dropdownRef.current.getBoundingClientRect();
        switch (Placement) {
          case "top":
            top = -dropDownRect.height - 10;
            left = 0;
            // translateY = targetRect.top - dropDownRect.height - 10;
            // translateX = targetRect.right - dropDownRect.width;
            break;
          case "bottom":
            top = targetRect.height;
            left = 0;
            // translateY = targetRect.top + targetRect.height;
            // translateX = targetRect.right + targetRect.width - dropDownRect.width;
            break;
          case "left":
            left = -(dropDownRect.width - targetRect.width);
            top = targetRect.height;
            // translateX = targetRect.right;
            // translateY = targetRect.top + targetRect.height;
            break;
          case "right":
            top = targetRect.height;
            left = 0;
            // translateX = targetRect.right + targetRect.width - dropDownRect.width;
            // translateY = targetRect.top + targetRect.height;
            break;
          case "center":
            top = 0;
            left = 0;
            // translateX =
            //   targetRect.left + targetRect.width / 2 - dropDownRect.width / 2;
            // translateY = targetRect.top + targetRect.height;
            break;
          default:
            break;
        }
        // setTranslate({ translateX, translateY });
        setPlace({ top, left, right, bottom });
      };

      handlePlacement();

      window.addEventListener("resize", handlePlacement);

      if (!show) {
        window.removeEventListener("resize", handlePlacement);
      }
    }

    return () => {
      window.removeEventListener("resize", handlePlacement);
    };
  }, [targetEle, show, Placement]);

  if (!show || !targetEle) return null;

  const setStyle = (defaultValue, value) => {
    return value !== defaultValue ? `${value}px` : `${defaultValue}`;
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className={`dropdown_wrapper min-w-[250px] absolute  !z-[999] bg-white  ${wrapperClasses}`}
          ref={dropdownRef}
          style={{
            top: setStyle("auto", place.top),
            bottom: setStyle("auto", place.bottom),
            left: setStyle("auto", place.left),
            right: setStyle("auto", place.right),
            // transform: `translate(${
            //   setStyle(0, translate.translateX) +
            //   "," +
            //   setStyle(0, translate.translateY)
            // })`,
          }}
        >
          {/* overlay */}
          <div
            className={`dropdown w-full min-h-[100px] z-50 px-[8px] py-[16px] rounded-md customShadow`}
          >
            {/* header */}
            <div>
              {headerComponent ? (
                headerComponent
              ) : (
                <div>
                  <p>{headerText ? headerText : "Title"}</p>
                </div>
              )}
            </div>

            {/* body */}
            {children}
          </div>
        </div>,
        targetEle
      )}
    </>
  );
}

// prop validation
Dropdown.propTypes = {
  headerComponent: PropTypes.element,
  headerText: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node,
  isBodyClickToClose: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  placement: PropTypes.string,
};

export default Dropdown;
