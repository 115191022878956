import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Brand from "../../assets/vector-1@2x.png";
import { TopPlaceHolderInput } from "../../components/widgets/customInput/CustomInput";
import { confirmSignUp } from "../../apis/auth/authAPI";

const SignUpCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState("");
  const userName = location.state;

  // TO DO: call api
  function handleSubmit(e) {
    e.preventDefault();
    confirmSignUp(userName, code)
    .then(async () => {
      // navigate to input Your confirmation code
      navigate("/auth-success");
    }).catch(err => {
      // display error
      console.log(err);
    });
  }

  return (
    <section className="w-full">
      <div className="costomContainer">
        <div className="flex flex-col w-full lg:gap-[80px] md:gap-[60px] sm:gap-[50px] gap-[40px] justify-center">
          <div className="flex justify-center items-center pt-6">
            <img className="sm:h-[32px] h-[24px]" src={Brand} alt="Brand" />
          </div>
          {/* login & signup body */}
          <div className="sm:w-[362px] w-full mx-auto shadow p-6 rounded-md bg-white border border-[#F3F3F3]">
            <div className="w-full flex flex-col gap-[24px] text-center">
              <div className="w-full">
                <h2 className="md:text-[32px] sm:text-[28px] text-[24px] font-mono font-bold text-black">
                  Enter Verification code
                </h2>
              </div>
              <div className="w-full">
                <p className="sm:text-[16px] text-sm text-[#545454]">
                  Enter the 6-digit code sent to your email address.
                </p>
              </div>
              {/* verification code */}
              <div className="w-full">
                <TopPlaceHolderInput
                  type="text"
                  name={"code"}
                  htmlFor={"code"}
                  onChange={e => setCode(e.target.value)}
                  placeHolder="Verification code"
                  isRequired
                />
              </div>
              {/* signup button */}
              <div className="w-full">
                <button
                  onClick={handleSubmit}
                  className="w-full py-[19px] px-4 bg-primary text-white rounded-[4px] hover:bg-purple-600 text-sm hover:text-white"
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpCode;
