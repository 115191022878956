import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const client = new S3Client({
  region: import.meta.env.VITE_AWS_S3_REGION,
  credentials: {
      accessKeyId: import.meta.env.VITE_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: import.meta.env.VITE_AWS_S3_SECRET_ACCESS_KEY,
  }
});

export const UploadFile = async (file) => {
  const fileName = crypto.randomUUID() + '.jpeg';

  const command = new PutObjectCommand({
    Bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
    Key: fileName,
    Body: file,
  });

  try {
    const response = await client.send(command);
    console.log(response);
  } catch (err) {
    console.error(err);
  }

  return import.meta.env.VITE_AWS_S3_OBJECT_URL + fileName;
}