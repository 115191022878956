/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import DatePicker from "../../../components/ul/datepicker/Datepicker";
import CustomSelect from "../../../components/ul/select/Select";
import CustomInput from "../../../components/widgets/customInput/CustomInput";
import CustomFileUploader from "../../../components/widgets/fileuploader/FileUploader";
import AutoCompleteSearchBar from "../../../components/widgets/autocompletesearch/AutoCompleteSearchBar";
import { Icons } from "../../../shared/lib/Icon";
import "./addItem.css";
import { useNavigate } from "react-router-dom";
import { UploadFile } from "../../../apis/product/uploadFile";
import { ProductAPI } from "../../../apis/product/productAPI";

const AddItem = () => {
  const [selectedCondition, setSelectedCondition] = useState("");

  const handleConditionChange = (e) => {
    setSelectedCondition(e.target.value);
  };

  // sql: title, gender, brand, size, condition, owner_id, image_url, tag_image_url, note, auth_date
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [gender, setGender] = useState("");
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState("");
  const [owner_id, setOwner_Id] = useState("");
  const [image_file, setImage_File] = useState("");
  const [tag_image_file, setTag_Image_File] = useState("");
  const [note, setNote] = useState("");
  const [auth_date, setAuth_Date] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const handleImageRemove1 = () => {
    if(image_file != null){
      setImage_File(null);
    }
    
  };

  const handleImageRemove2 = () => {
    if(tag_image_file != null){
      setTag_Image_File(null);
    }
    
  };

  useEffect(() => {
    // Define the function inside useEffect
    const checkFormCompleteness = () => {
      const isComplete = title && brand && gender && size && condition && owner_id && image_file && tag_image_file;
      setIsFormComplete(isComplete);
    };
  
    // Call the function
    checkFormCompleteness();
  }, [title, brand, gender, size, condition, owner_id, image_file, tag_image_file]);

  useEffect(() => {
    const currentDate = new Date(); // This gets the current date and time
    setAuth_Date(currentDate); // Sets authDate state to the current date
    console.log(auth_date);
  }, []); 


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      const image_url = await UploadFile(image_file);
      const tag_image_url = await UploadFile(tag_image_file);
      await ProductAPI.create({
        'title': title, 
        'brand': brand,
        'gender': gender,
        'size': size,
        'condition': condition,
        'owner_id': owner_id,
        'image_url': image_url,
        'tag_image_url': tag_image_url,
        'note': note,
        'auth_date': auth_date,
      });
      navigate("/", {
        state: {
          message: `Item "${title}" has been added to the database.`
        }
      });
    } catch (e) {
      console.log('can not add Item', e);
    }
  }

  return (
    <div className="w-full md:py-[56px] py-[40px] overflow-hidden add_item_page">


      <div className="flex w-full gap-6 flex-col">
        {/*  */}
        <div className="flex flex-col w-full sm:gap-[8px] gap-[4px]">
          <h2 className="sm:large-font text-[24px] font-bold sm:heading">
            Add item
          </h2>
          <p className="text-[16px] text-black tracking-[0.5px]">
            Item added will be saved to the inventory of the VRFY username
            provided.
          </p>
        </div>
        {/* form photo upload section */}
        <div className="w-full flex flex-col gap-6 p-6 bg-white shadow rounded-md">
          <div className="flex gap-6 lg:flex-row flex-col items-start">
            {/* left side */}
            <form onSubmit={handleSubmit} className=" flex-1 w-full flex flex-col gap-6">
              {/* item name */}
              <div className="w-full relative">
                <CustomInput
                  name="title"
                  placeHolder={"Item name"}
                  onChange={e => setTitle(e?.target?.value)}
                />
              </div>
              {/* brand */}
              <div className="w-full">
                <div className="w-full">
                  <CustomSelect
                    name="brand"
                    isRequired
                    options={[
                      { value: "Nike", label: "Nike" },
                      { value: "Jordan", label: "Jordan" },
                      { value: "Adidas", label: "Adidas" },
                      { value: "Yeezy", label: "Yeezy" },
                      { value: "New Balance", label: "New Balance" },
                      { value: "ASICS", label: "ASICS" },
                      { value: "Reebok", label: "Reebok" },
                      { value: "Converse", label: "Converse" },
                      { value: "Vans", label: "Vans" },
                      { value: "Puma", label: "Puma" },
                      { value: "Under Armour", label: "Under Armour" },
                      { value: "Skechers", label: "Skechers" },
                      { value: "Timberland", label: "Timberland" },
                      { value: "Dr. Martens", label: "Dr. Martens" },
                      { value: "Clarks", label: "Clarks" },
                      { value: "Merrell", label: "Merrell" },
                      { value: "Brooks", label: "Brooks" },
                      { value: "Saucony", label: "Saucony" },
                      { value: "Hoka One One", label: "Hoka One One" },
                      { value: "Salomon", label: "Salomon" },
                      { value: "Birkenstock", label: "Birkenstock" },
                      { value: "Crocs", label: "Crocs" },
                      { value: "Keds", label: "Keds" },
                      { value: "Steve Madden", label: "Steve Madden" },
                      { value: "Aldo", label: "Aldo" },
                      { value: "Cole Haan", label: "Cole Haan" },
                      { value: "Toms", label: "Toms" },
                      { value: "ECCO", label: "ECCO" },
                      { value: "Rockport", label: "Rockport" },
                      { value: "Keen", label: "Keen" },
                      { value: "Johnston & Murphy", label: "Johnston & Murphy" },
                      { value: "Hugo Boss", label: "Hugo Boss" },
                      { value: "Giuseppe Zanotti", label: "Giuseppe Zanotti" },
                      { value: "Balenciaga", label: "Balenciaga" },
                      { value: "Gucci", label: "Gucci" },
                      { value: "Prada", label: "Prada" },
                      { value: "Versace", label: "Versace" },
                      { value: "Dolce & Gabbana", label: "Dolce & Gabbana" },
                      { value: "Christian Louboutin", label: "Christian Louboutin" },
                      { value: "Jimmy Choo", label: "Jimmy Choo" },
                      { value: "Manolo Blahnik", label: "Manolo Blahnik" },
                      { value: "Alexander McQueen", label: "Alexander McQueen" },
                      { value: "Fendi", label: "Fendi" },
                      { value: "Valentino", label: "Valentino" },
                      { value: "Red Wing Shoes", label: "Red Wing Shoes" },
                      { value: "Wolverine", label: "Wolverine" },
                      { value: "Danner", label: "Danner" },
                      { value: "Blundstone", label: "Blundstone" },
                      { value: "Bata", label: "Bata" },
                      { value: "Zara", label: "Zara" },
                    ]}
                    
                    placeholder="Brand"
                    onChange={e => setBrand(e?.value)}
                  />
                </div>
              </div>
              {/* gender & size */}
              <div className="w-full flex  gap-5">
              {/* gender */}
              <div className="w-full">
                <CustomSelect
                  name="gender"
                  isRequired
                  options={[
                    { value: "Men", label: "Men" },
                    { value: "Women", label: "Women" },
                    { value: "Big Kids", label: "Big Kids" },
                    { value: "Little Kids", label: "Little Kids" },
                  ]}
                  placeholder="Gender"
                  onChange={e => setGender(e?.value)}
                />
              </div>
              {/* size */}
              <div className="w-full ">
                    <CustomSelect
                      name="size"
                      isRequired
                      options={
                        gender === "Men"
                          ? [
                            { value: "US 6", label: "US 6" },
                            { value: "US 6.5", label: "US 6.5" },
                            { value: "US 7", label: "US 7" },
                            { value: "US 7.5", label: "US 7.5" },
                            { value: "US 8", label: "US 8" },
                            { value: "US 8.5", label: "US 8.5" },
                            { value: "US 9", label: "US 9" },
                            { value: "US 9.5", label: "US 9.5" },
                            { value: "US 10", label: "US 10" },
                            { value: "US 10.5", label: "US 10.5" },
                            { value: "US 11", label: "US 11" },
                            { value: "US 11.5", label: "US 11.5" },
                            { value: "US 12", label: "US 12" },
                            { value: "US 12.5", label: "US 12.5" },
                            { value: "US 13", label: "US 13" },
                            { value: "US 13.5", label: "US 13.5" },
                            { value: "US 14", label: "US 14" },
                            { value: "US 14.5", label: "US 14.5" },
                            { value: "US 15", label: "US 15" },
                            { value: "US 15.5", label: "US 15.5" },
                            { value: "US 16", label: "US 16" },
                            { value: "US 16.5", label: "US 16.5" },
                            { value: "US 17", label: "US 17" },
                            { value: "US 17.5", label: "US 17.5" },
                            { value: "US 18", label: "US 18" },
                            { value: "US 18.5", label: "US 18.5" },
                            { value: "US 19", label: "US 19" },
                            { value: "US 19.5", label: "US 19.5" },
                            { value: "US 20", label: "US 20" },
                            { value: "US 20.5", label: "US 20.5" },
                            ]
                          : gender === "Women"
                          ? [
                            { value: "US 4", label: "US 4" },
                            { value: "US 4.5", label: "US 4.5" },
                            { value: "US 5", label: "US 5" },
                            { value: "US 5.5", label: "US 5.5" },
                            { value: "US 6", label: "US 6" },
                            { value: "US 6.5", label: "US 6.5" },
                            { value: "US 7", label: "US 7" },
                            { value: "US 7.5", label: "US 7.5" },
                            { value: "US 8", label: "US 8" },
                            { value: "US 8.5", label: "US 8.5" },
                            { value: "US 9", label: "US 9" },
                            { value: "US 9.5", label: "US 9.5" },
                            { value: "US 10", label: "US 10" },
                            { value: "US 10.5", label: "US 10.5" },
                            { value: "US 11", label: "US 11" },
                            { value: "US 11.5", label: "US 11.5" },
                            { value: "US 12", label: "US 12" },
                            { value: "US 12.5", label: "US 12.5" },
                            { value: "US 13", label: "US 13" },
                            { value: "US 13.5", label: "US 13.5" },
                            { value: "US 14", label: "US 14" },
                            { value: "US 14.5", label: "US 14.5" },
                            { value: "US 15", label: "US 15" },
                            { value: "US 15.5", label: "US 15.5" },
                            { value: "US 16", label: "US 16" },
                            ]
                          : gender === "Big Kids"
                          ? [
                            { value: "US 3", label: "US 3" },
                            { value: "US 3.5", label: "US 3.5" },
                            { value: "US 4", label: "US 4" },
                            { value: "US 4.5", label: "US 4.5" },
                            { value: "US 5", label: "US 5" },
                            { value: "US 5.5", label: "US 5.5" },
                            { value: "US 6", label: "US 6" },
                            { value: "US 6.5", label: "US 6.5" },
                            { value: "US 7", label: "US 7" },
                            ]
                            : gender === "Little Kids"
                            ? [
                              { value: "US 10.5", label: "US 10.5Y" },
                              { value: "US 11", label: "US 11Y" },
                              { value: "US 11.5", label: "US 11.5Y" },
                              { value: "US 12", label: "US 12Y" },
                              { value: "US 12.5", label: "US 12.5Y" },
                              { value: "US 13", label: "US 13Y" },
                              { value: "US 13.5", label: "US 13.5Y" },
                              { value: "US 1", label: "US 1" },
                              { value: "US 1.5", label: "US 1.5" },
                              { value: "US 2", label: "US 2" },
                              { value: "US 2.5", label: "US 2.5" },
                              { value: "US 3", label: "US 3" },
                              ]
                          : []
                      }
                      placeholder="Size"
                      onChange={e => setSize(e?.value)}
                    />
                  </div>
                </div>

              {/* condition */}
              <div className="w-full">
                <CustomSelect
                  name="condition"
                  isRequired
                  options={[
                    { value: "Deadstock/New", label: "Deadstock/New" },
                    { value: "Excellent", label: "Excellent" },
                    { value: "Good", label: "Good" },
                    { value: "Fair", label: "Fair" },
                    { value: "Worn", label: "Worn" },
                    { value: "Poor", label: "Poor" },
                  ]}
                  placeholder="Condition"
                  onChange={e => setCondition(e?.value)}
                />
              </div>
              {/* Owner’s VRFY username */}
              <div className="w-full">
                {/* <AutoCompleteSearchBar
                  name="owner_id"
                  placeHolder={"Owner's VRFY username"}
                  isRequired={true}
                  onChange={e => setOwner_Id(e)}
                /> */}
                <CustomInput
                  name="owner_id"
                  placeHolder={"Owner's VRFY username"}
                  isRequired={true}
                  onChange={e => setOwner_Id(e?.target?.value)}
                />
              </div>
              {/* Notes after inspection */}
              <div className="w-full border border-[#F3F3F3] rounded-md customShadow py-[9px] px-4 ">
                <textarea
                  className="w-full  outline-none  resize-none  h-[124px]"
                  placeholder="Notes after inspection"
                  type="text"
                  name="note"
                  id=""
                  onChange={e => setNote(e?.target?.value)}
                ></textarea>
                <div className="flex items-center justify-end w-full">
                  <span className="text-[10px] text-gray">0</span>
                  <span className="text-[10px] text-gray">/</span>
                  <span className="text-[10px] text-gray">2500</span>
                </div>
              </div>
              {/* right side  file upload*/}
              <div className="flex flex-1 flex-col gap-6 w-full">
                <div className="flex flex-col">
                  <div className="pb-4">
                    <p className="text-[16px] text-black">
                      Sneakers photo upload: <span className="text-red">*</span>
                    </p>
                  </div>
                  <div className="   w-full">
                    <CustomFileUploader
                      icon={Icons?.sneaker}
                      name="image_file"
                      onChange={e => setImage_File(e?.target?.files[0])}
                      onRemove={handleImageRemove1} // Add this line
                    />
                  </div>
                </div>
                {/*  */}
                <div className="flex flex-col">
                  <div className="pb-4">
                    <p className="text-[16px] text-black">
                      VRFY tag photo upload: <span className="text-red">*</span>
                    </p>
                  </div>
                  <div className="  w-full">
                  <CustomFileUploader
                    icon={Icons?.photoView} // Assuming this is the correct icon for tag image
                    name="tag_image_file"
                    onChange={e => setTag_Image_File(e?.target?.files[0])}
                    onRemove={handleImageRemove2}
                  />
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center sm:justify-end justify-center sm:flex-row flex-col">
                <button className="py-[15px] px-6 border-none outline-none text-sm text-primary rounded-md w-full sm:w-max sm:order-1 order-2">
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`py-[15px] px-6 border-none outline-none text-sm text-primary rounded-md w-full sm:w-max sm:order-2 order-1 ${isFormComplete ? 'bg-purple-500 text-white' : 'bg-[#CCCCCC] text-[#545454]'}`}
                >
                  Add item
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItem;
