import { useState } from "react";
import Avatar from "../../../assets/profile.png";
import { uploadProfilePicture, getTokenByName } from "../../../apis/auth/authAPI";

function ProfileUploader({ onHide, picture }) {
  const [previewURl, setPreviewURL] = useState(picture);
  const [newPicture, setNewPicture] = useState(null);
  const onFileChangeHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        console.log(e);
        setPreviewURL(e?.target?.result);
      };
      reader.readAsDataURL(file);

      setNewPicture(file);
    }
  };

  const upload = async () => {
    try {
      await uploadProfilePicture(newPicture);
      setPreviewURL(getTokenByName('picture'));
      onHide();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="p-[20px]">
      {/* img  */}
      <figure className="w-[304px] h-[304px]  rounded-full overflow-hidden mb-[24px]  profile-upload">
        <img
          src={previewURl ? previewURl : Avatar}
          alt="avatar"
          className="w-full h-full"
        />
      </figure>
      <p className="small-font text-black mt-1">
        Only JPEG and PNG with max size of 5MB.
      </p>
      <div>
        <label
          htmlFor="profileUpload"
          className="block w-full bg-primary py-[19px] text-center text-white small-font rounded-md cursor-pointer"
        >
          Choose file
        </label>
        <input
          type="file"
          id="profileUpload"
          accept="image/jpg , image/png ,image/jpeg"
          className="hidden"
          onChange={onFileChangeHandler}
        />
      </div>

      {/* submit button  */}
      {previewURl &&
        <button
          type="button"
          className="text-primary text-center w-full  mt-[16px] py-[19px] border border-primary rounded-lg"
          onClick={upload}
        >
          Upload picture
        </button>
      }

      {/* take photo  */}
      {/* <div className="mt-6">
        <label
          htmlFor="profileUploadCamera"
          className="block w-full border border-primary py-[19px] text-center text-primary small-font rounded-md md:hidden cursor-pointer"
        >
          Take photo
        </label>
        <input
          type="file"
          id="profileUploadCamera"
          accept="image/jpg , image/png ,image/jpeg"
          className="hidden"
          capture="camera"
          onChange={onFileChangeHandler}
        />
      </div> */}

      {/* delete image  */}
      {/* <button
        type="button"
        className="text-primary text-center w-full  mt-[16px] py-[19px] "
      >
        Delete image
      </button> */}
    </div>
  );
}

export default ProfileUploader;
