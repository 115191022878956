import { SingleStore } from "../../../components/ul";
import CustomSelect from "../../../components/ul/select/Select";

const shopList = [
  {
    _id: 1,
    shopName: "Flight Club - Broadway",
    address: "812 Broadway, New York, NY 10003, United States",
    contract: "1 234 567 8910",
    storeOpen: "Monday - Sunday 11AM - 7PM ET",
  },
  {
    _id: 2,
    shopName: "Flight Club - Broadway",
    address: "812 Broadway, New York, NY 10003, United States",
    contract: "1 234 567 8910",
    storeOpen: "Monday - Sunday 11AM - 7PM ET",
  },
  {
    _id: 3,
    shopName: "Flight Club - Broadway",
    address: "812 Broadway, New York, NY 10003, United States",
    contract: "1 234 567 8910",
    storeOpen: "Monday - Sunday 11AM - 7PM ET",
  },
  {
    _id: 4,
    shopName: "Flight Club - Broadway",
    address: "812 Broadway, New York, NY 10003, United States",
    contract: "1 234 567 8910",
    storeOpen: "Monday - Sunday 11AM - 7PM ET",
  },
  {
    _id: 5,
    shopName: "Flight Club - Broadway",
    address: "812 Broadway, New York, NY 10003, United States",
    contract: "1 234 567 8910",
    storeOpen: "Monday - Sunday 11AM - 7PM ET",
  },
];

const StoreList = () => {
  return (
    <div  className="py-[40px] md:py-[56px]">
      <h1 className="heading ">Store List</h1>
      <div className="flex sm:flex-row flex-col items-center justify-start lg:w-[70%] w-full lg:gap-8 gap-4 my-6">
        <div className="w-full">
          <CustomSelect
            isRequired={false}
            options={[
              { value: 'Alabama', label: 'Alabama' },
              { value: 'Alaska', label: 'Alaska' },
              { value: 'Arizona', label: 'Arizona' },
              { value: 'Arkansas', label: 'Arkansas' },
              { value: 'California', label: 'California' },
              { value: 'Colorado', label: 'Colorado' },
              { value: 'Connecticut', label: 'Connecticut' },
              { value: 'Delaware', label: 'Delaware' },
              { value: 'Florida', label: 'Florida' },
              { value: 'Georgia', label: 'Georgia' },
              { value: 'Hawaii', label: 'Hawaii' },
              { value: 'Idaho', label: 'Idaho' },
              { value: 'Illinois', label: 'Illinois' },
              { value: 'Indiana', label: 'Indiana' },
              { value: 'Iowa', label: 'Iowa' },
              { value: 'Kansas', label: 'Kansas' },
              { value: 'Kentucky', label: 'Kentucky' },
              { value: 'Louisiana', label: 'Louisiana' },
              { value: 'Maine', label: 'Maine' },
              { value: 'Maryland', label: 'Maryland' },
              { value: 'Massachusetts', label: 'Massachusetts' },
              { value: 'Michigan', label: 'Michigan' },
              { value: 'Minnesota', label: 'Minnesota' },
              { value: 'Mississippi', label: 'Mississippi' },
              { value: 'Missouri', label: 'Missouri' },
              { value: 'Montana', label: 'Montana' },
              { value: 'Nebraska', label: 'Nebraska' },
              { value: 'Nevada', label: 'Nevada' },
              { value: 'New Hampshire', label: 'New Hampshire' },
              { value: 'New Jersey', label: 'New Jersey' },
              { value: 'New Mexico', label: 'New Mexico' },
              { value: 'New York', label: 'New York' },
              { value: 'North Carolina', label: 'North Carolina' },
              { value: 'North Dakota', label: 'North Dakota' },
              { value: 'Ohio', label: 'Ohio' },
              { value: 'Oklahoma', label: 'Oklahoma' },
              { value: 'Oregon', label: 'Oregon' },
              { value: 'Pennsylvania', label: 'Pennsylvania' },
              { value: 'Rhode Island', label: 'Rhode Island' },
              { value: 'South Carolina', label: 'South Carolina' },
              { value: 'South Dakota', label: 'South Dakota' },
              { value: 'Tennessee', label: 'Tennessee' },
              { value: 'Texas', label: 'Texas' },
              { value: 'Utah', label: 'Utah' },
              { value: 'Vermont', label: 'Vermont' },
              { value: 'Virginia', label: 'Virginia' },
              { value: 'Washington', label: 'Washington' },
              { value: 'West Virginia', label: 'West Virginia' },
              { value: 'Wisconsin', label: 'Wisconsin' },
              { value: 'Wyoming', label: 'Wyoming' }
            ]}
            placeholder="Select state"
            className={"shadow-lg rounded-lg !border-none"}
          />
        </div>

        {/* select two  */}
        {/* <div className="w-full">
          <CustomSelect
            isRequired={false}
            options={[
              { value: "Samsung", label: "Samsung" },
              { value: "Nokia", label: "Nokia" },
            ]}
            placeholder="Select city"
            className={"shadow-lg rounded-lg !border-none"}
          />
        </div> */}
      </div>

      <div className=" my-6 customShadow">
        {shopList?.map((item) => (
          <div key={item._id}>
            <SingleStore item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoreList;
