import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import { UploadProfileFile } from './uploadProfileFile';

const cognitoUserPool = () => (
  new CognitoUserPool({
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_CLIENT_ID
  })
);

const setCognitoUserAttribute = (attributeKey, attributeValue) => {
  let data = {
    Name: attributeKey,
    Value: attributeValue
  };
  return new CognitoUserAttribute(data);
}

export const loginCognitoUser = (email, password)=> {
  const userPool = cognitoUserPool();
  const user = new CognitoUser({ Username: email, Pool: userPool });
  const authData = { Username: email, Password: password };
  const authDetails = new AuthenticationDetails(authData);

  return new Promise((resolve, reject)=> {
    user.authenticateUser(authDetails, {
      onSuccess:(result)=>{
        console.log("Login Successful");
        setToken(result);
        resolve(result);
      },
      onFailure:(err)=>{
        console.log("login failed",err);
        reject(err);
      }
    });
  });
};

export const signupCognitoUser = (firstName, lastName, userName, email, password, gender, birthdate) => {
  const userPool = cognitoUserPool();
  let attributeList = [];

  attributeList.push(setCognitoUserAttribute('given_name', firstName));
  attributeList.push(setCognitoUserAttribute('family_name', lastName));
  attributeList.push(setCognitoUserAttribute('name', userName));
  attributeList.push(setCognitoUserAttribute('email', email));
  if (gender) {
    attributeList.push(setCognitoUserAttribute('gender', gender));
  }
  if (birthdate) {
    attributeList.push(setCognitoUserAttribute('birthdate', birthdate));
  }

  return new Promise((resolve, reject) => 
    userPool.signUp(
      userName,
      password,
      attributeList,
      null,
      (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    })
  );

}

export const updateCongintoUser = async (firstName, lastName, username, email, oldPassword, newPassword) => {
  try {
    const userPool = cognitoUserPool();
    const user = userPool.getCurrentUser();
    await new Promise(res => user.getSession(res));

    let attributeList = [];
    attributeList.push(setCognitoUserAttribute('given_name', firstName));
    attributeList.push(setCognitoUserAttribute('family_name', lastName));
    // attributeList.push(setCognitoUserAttribute('name', username));
    // attributeList.push(setCognitoUserAttribute('email', email));

    await user.updateAttributes(attributeList, function (err, result) {
      if (result == "SUCCESS") {
        localStorage.setItem('userName', username);
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('email', email);
        return;
      } else {
        return err;
      }
    });
  } catch (err) {
    return err;
  }
}

export const uploadProfilePicture = async (picture) => {
  try {
    // store at S3
    const profile_url = await UploadProfileFile(picture);
    // update cognito
    const userPool = cognitoUserPool();
    const user = userPool.getCurrentUser();
    await new Promise(res => user.getSession(res));

    let attributeList = [];
    attributeList.push(setCognitoUserAttribute('picture', profile_url));

    await user.updateAttributes(attributeList, function (err, result) {
      if (result == "SUCCESS") {
        localStorage.setItem('picture', profile_url);
        return;
      } else {
        return err;
      }
    });
  } catch (err) {
    return err;
  }
}

export const confirmSignUp = (username, code) => {
  const userPool = cognitoUserPool();
  const user = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err)
        return
      }
      resolve(result)
    })
  })
}

// forgot password
// 1. send confirmation code to user's email
export const resetPassword = (username) => {
  const userPool = cognitoUserPool();
  const user = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess:(result)=>{
        console.log('call result: ', result);
      },
      onFailure:(err)=>{
        console.error('error', err);
      }
    });
  })
}

// 2. collect confirmation code and new password
export const setNewPasswordWithCode = (username, code, newPassword) => {
  const userPool = cognitoUserPool();
  const user = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      }
    })
  })
}

export const getCurrentUser = () => {
  const userPool = cognitoUserPool();
  return userPool.getCurrentUser();
}

function setToken(token) {
  localStorage.setItem('access_token', token?.idToken?.jwtToken);
  localStorage.setItem('userName', token?.accessToken?.payload?.username);
  localStorage.setItem('firstName', token?.idToken?.payload?.given_name);
  localStorage.setItem('lastName', token?.idToken?.payload?.family_name);
  localStorage.setItem('email', token?.idToken?.payload?.email);
  localStorage.setItem('picture', token?.idToken?.payload?.picture);
  if (token?.idToken?.payload?.['cognito:groups']) {
    const groups = token?.idToken?.payload?.['cognito:groups'];
    if (groups.find(group => group == 'authorized_store')) {
      localStorage.setItem('authorized_store', true);
    }
    
    
  }
}

export function getToken() {
  try {
    return localStorage.getItem('access_token');
  } catch (err) {
    return null
  }
}

export function getTokenByName(name) {
  try {
    return localStorage.getItem(name);
  } catch (err) {
    return null
  }
}

export function removeToken() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('userName');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('email');
  localStorage.removeItem('authorized_store');
  localStorage.removeItem('picture');
}

export function readToken() {
  try {
    const token = getToken();
    return token? jwt_decode(token) : null
  } catch (err) {
    return null
  }
}

export function isAuthenticated () {
  const token = readToken();
  return token ? true : false
}

