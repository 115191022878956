/* eslint-disable react/prop-types */
import { RiDeleteBin6Line } from "react-icons/ri";
import { Icons } from "../../../shared/lib/Icon";
import { CopyLink } from "../../../apis/product/copyLink";

function TableSettingModal({onTransfer, id, onDelete}) {
  return (
    <ul className="flex flex-col">
      <li>
        <button
          type="button"
          className={`small-font  flex  w-full items-center gap-1 py-2 px-2 hover:bg-black-alpha-10 rounded-md`}
          onClick={typeof onTransfer === "function" ? onTransfer : () => {}}
        >
          <Icons.Transfer fillColor="#080808" />
          <span>Transfer ownership</span>
        </button>
      </li>

      <li>
        <button
          type="button"
          className={`small-font w-full  flex  items-center gap-1 py-2 px-2 hover:bg-black-alpha-10 rounded-md`}
          onClick={e => CopyLink(id)}
        >
          <span>
            <Icons.Copy fillColor="#080808" />
          </span>
          <span> Copy link</span>
        </button>
      </li>

      <li>
        <button
          type="button"
          className={`small-font w-full  flex  items-center gap-1 py-2 px-2 hover:bg-black-alpha-10 rounded-md`}
          onClick={typeof onDelete === "function" ? onDelete : () => {}}
        >
          <span className="text-[23px] text-[#020202]">
            <RiDeleteBin6Line />
          </span>
          <span> Delete</span>
        </button>
      </li>
    </ul>
  );
}

export default TableSettingModal;
