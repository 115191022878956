/* eslint-disable react/prop-types */
import moment from "moment/moment";
import { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Input = forwardRef(
  (
    { selected, Text, isRequired, onClick, onOpen = () => {}, className },
    ref
  ) => {
    return (
      <div
        className={`w-auto customShadow py-1 px-2 rounded-md border-[2px] border-gray ${className}`}
      >
        <div className="flex items-center justify-between">
          <div>
            <p className="small-font text-[1rem] !text-gray">
              {Text}
              {isRequired && <span className="  text-rose-600  ">*</span>}
            </p>
            <input
              type="text"
              placeholder="Select your time"
              className="focus:outline-0 small-font w-full"
              readOnly
              ref={ref}
              onClick={(e) => {
                e.stopPropagation;
                onClick();
                onOpen();
              }}
              value={selected ? moment(selected).format("DD MMMM yyyy") : ""}
            />
          </div>
          <div>
            <button type="button">
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e) => {
                  e.stopPropagation;
                  onClick();
                  onOpen();
                }}
              >
                <path
                  d="M20.125 22H3.875C3.11364 22 2.5 21.3864 2.5 20.625V10.4933C2.54101 10.4977 2.58271 10.5 2.625 10.5H2.75V20.625C2.75 21.2511 3.24886 21.75 3.875 21.75H20.125C20.7511 21.75 21.25 21.2511 21.25 20.625V10.5H21.375C21.4173 10.5 21.459 10.4977 21.5 10.4933V20.625C21.5 21.3864 20.8864 22 20.125 22ZM2.5 8.25671V5.625C2.5 4.86364 3.11364 4.25 3.875 4.25H6.5V4.5H3.875C3.24886 4.5 2.75 4.99886 2.75 5.625V8.25H2.625C2.58271 8.25 2.54101 8.25227 2.5 8.25671ZM3.75 9.25H20.25V9.5H3.75V9.25ZM21.25 9.5V9.25H21.375C21.4159 9.25 21.4454 9.265 21.4652 9.2848C21.485 9.30461 21.5 9.33411 21.5 9.375C21.5 9.41589 21.485 9.44539 21.4652 9.4652C21.4454 9.485 21.4159 9.5 21.375 9.5H21.25ZM7.5 4.5V4.25H7.75V4.5H7.5ZM7.75 3.25H7.5V3.125C7.5 3.08411 7.515 3.05461 7.5348 3.0348C7.55461 3.015 7.58411 3 7.625 3C7.66589 3 7.69539 3.015 7.7152 3.0348C7.735 3.05461 7.75 3.08411 7.75 3.125V3.25ZM8.75 4.25H15.25V4.5H8.75V4.25ZM16.25 4.5V4.25H16.5V4.5H16.25ZM17.5 4.25H20.125C20.8864 4.25 21.5 4.86364 21.5 5.625V8.25671C21.459 8.25227 21.4173 8.25 21.375 8.25H21.25V5.625C21.25 4.99886 20.7511 4.5 20.125 4.5H17.5V4.25ZM16.25 5.5H16.5V6.875C16.5 6.91589 16.485 6.94539 16.4652 6.9652C16.4454 6.985 16.4159 7 16.375 7C16.3341 7 16.3046 6.985 16.2848 6.9652C16.265 6.94539 16.25 6.91589 16.25 6.875V5.5ZM7.5 5.5H7.75V6.875C7.75 6.91589 7.735 6.94539 7.7152 6.9652C7.69539 6.985 7.66589 7 7.625 7C7.58411 7 7.55461 6.985 7.5348 6.9652C7.515 6.94539 7.5 6.91589 7.5 6.875V5.5ZM16.25 3.25V3.125C16.25 3.08411 16.265 3.05461 16.2848 3.0348C16.3046 3.015 16.3341 3 16.375 3C16.4159 3 16.4454 3.015 16.4652 3.0348C16.485 3.05461 16.5 3.08411 16.5 3.125V3.25H16.25ZM2.75 9.25V9.5H2.625C2.58411 9.5 2.55461 9.485 2.5348 9.4652C2.515 9.44539 2.5 9.41589 2.5 9.375C2.5 9.33411 2.515 9.30461 2.5348 9.2848C2.55461 9.265 2.58411 9.25 2.625 9.25H2.75Z"
                  fill="#020202"
                  stroke="#A5A5A5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
);

Input.displayName = "CustomDatePickerInput";

const DatePicker = ({
  placeholder,
  title,
  isRequired,
  isClear,
  onOpen,
  className,
  getDate,
}) => {
  const [startDate, setStartDate] = useState(placeholder ? new Date() : null);

  useEffect(() => {
    if (isClear) {
      setStartDate(null);
    }
  }, [isClear]);
  const changeHandler = (e) => {
    setStartDate(e);
    // getDate(moment(e).format("DD MMMM yyyy"));
    if (typeof getDate === 'function') {
      getDate(e);
    }
  }


  return (
    <ReactDatePicker
      startDate={startDate}
      onChange={(e) => changeHandler(e)}
      placeholderText={placeholder ? placeholder : ""}
      customInput={
        <Input
          selected={startDate ? startDate : null}
          Text={title}
          isRequired={isRequired}
          onOpen={onOpen}
          className={className}
        />
      }
      required={isRequired}
    />
  );
};

export default DatePicker;
