/* eslint-disable react/prop-types */

import CustomInput from "../../widgets/customInput/CustomInput";
import React, { useState } from "react";
import { ProductAPI } from "../../../apis/product/productAPI";

function TransferOwner({ data, getMessage }) {
  const [inputs, setInputs] = useState({id : data?.id});

  const handleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setInputs(values => ({...values, [name]: value}));
  }
  const handleSubmit = (e) => {
    ProductAPI.edit(inputs)
    .then(data => {
      getMessage(`The ownership of ${data?.title} has been transferred.`);
    })
  }

  return (
    <div className="bg-white py-2">
      <div className="flex gap-[16px] items-center py-[24px] border-y border-gray">
        {/* image  */}
        <img
          src={data?.image || data?.image_url}
          alt="product image"
          className="w-[96px] h-[96px]"
        />
        <div>
          <h2 className="regular_font">{data?.title}</h2>
          <div className="">
            <p className="font-bold">{data?.gender}</p>
            <p>
              Size: <span className="font-bold">{data?.size}</span>
            </p>
            <p>
              Condition: <span className="font-bold">{data?.condition}</span>
            </p>
          </div>
        </div>
      </div>

      {/* form  */}

      <p className="regular_font mt-[15px]">Enter new owner details below:</p>
      <form onSubmit={handleSubmit} >
        <div className="my-[24px]">
          <CustomInput
            name="email"
            placeHolder={"Email address"}
            isRequired
            className={"!shadow-lg"}
            onChange={handleChange}
          />
        </div>
        <div className="mb-[24px]">
          <CustomInput
            name="owner_id"
            placeHolder={"VRFY username"}
            isRequired
            className={"!shadow-lg"}
            onChange={handleChange}
          />
        </div>

        <button
          type="submit"
          className="small-font w-full text-center py-[19px] bg-primary text-white rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default TransferOwner;
