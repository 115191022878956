import { Outlet } from "react-router-dom";
import { Footer } from "../../components/widgets/footer";

const AuthLayout = () => {
  return (
    <div className="authLayout">
      <main className="h-full ">
        <Outlet />
      </main>

      <footer className="md:pt-[100px] pt-[40px]">
        <Footer />
      </footer>
    </div>
  );
};

export default AuthLayout;
