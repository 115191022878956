import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useToast() {
  const showToast = (msg, options = {}, delay = 3000) => {
    const commonOptions = {
      autoClose: delay,
      closeOnClick: true,
      position: "bottom-center",
      pauseOnHover: false,
      hideProgressBar: true,
    };

    if (options.isSuccess) {
      return toast.success(msg, {
        ...commonOptions,
        className: options.className,
      });
    }
    if (options.isError) {
      return toast.error(msg, {
        ...commonOptions,
        className: options.className,
      });
    }
    if (options.isWarn) {
      return toast.warn(msg, {
        ...commonOptions,
        className: options.className,
      });
    }

    return toast(msg, commonOptions);
  };

  return { Toast: ToastContainer, showToast };
}

export default useToast;
