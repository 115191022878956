import React from 'react';
import { Icons } from '../../../shared/lib/Icon';




const SingleStore = ({ item }) => {
  return (
    <div className='p-6 custom_shadow_For_single_store'>
      <div className='flex'>
        <span>{Icons.location}</span>
        <strong className='ml-2'>{item.shopName}</strong>
      </div>
      <div className='lg:flex md:flex py-6'>

        <div className='lg:w-1/5 md:w-2/5'>
          <span className='font-bold'>Address</span>
          <p className='span_text mt-1 '>{item?.address}</p>
        </div>
        <div className='lg:w-1/5  md:w-1/5 lg:mx-6 md:mx-1 lg:my-0 md:my-0 my-4 '>
          <span className='font-bold'>Contract No</span>
          <p className='span_text mt-1 '>{item.contract}</p>
        </div>
        <div className='lg:w-2/5  md:w-2/5'>
          <span className='font-bold'>Store hours</span>
          <p className='span_text mt-1 '>{item.storeOpen}</p>
        </div>

      </div>

    </div>
  )
}

export default SingleStore
