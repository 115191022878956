/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Icons } from "../../../shared/lib/Icon";
import Modal from "../../ul/modal/Modal";
import Dropdown from "./Dropdown";
import TableSettingModal from "./TableSettingModal";
import TransferOwner from "./TransferOwner";
import { CopyLink } from "../../../apis/product/copyLink";

function TableRow({ data, className, isProducts, isInventory, getMessage }) {
  const row = data;
  const [isHover, setIsHover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const targetEleDrop = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [showDropDown, setShowDropDown] = useState(false);

  row.auth_date = (new Date(row?.auth_date)).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
  // body click to dropdown close

  useEffect(() => {
    if (isProducts) {
      setShowDropDown(false);
    }
  }, [isProducts]);

  const dropDownShowHandler = (e) => {
    e.stopPropagation();
    setShowDropDown(!showDropDown);
  };

  // const dropDownCloseHandler = (e) => {
  //   e.stopPropagation();
  //   setShowDropDown(false);
  // };

  const modalShowHandler = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };

  const hoverHandler = () => {
    setIsHover(true);
  };

  const hoverOutHandler = () => {
    setIsHover(false);
  };

  return (
    <>
      <tr
        key={row?.id}
        className={`  rounded-md  `}
        onMouseOverCapture={hoverHandler}
        onMouseLeave={hoverOutHandler}
      >
        <Link
          className={`${className}  lg:w-[288px] table-cell`}
          to={`/products/${row?.id}`}
          state={{item: row}}
        >
          <div className="flex gap-[16px] items-center">
            {/* image  */}
            <img
              src={row?.image || row?.image_url}
              alt="product image"
              className="w-[48px] h-[48px]"
            />
            <div>
              <h2 className="regular_font">{row?.title}</h2>
              <div className="flex  items-center gap-2 lg:block">
                <p className="lg:mb-[5px]">{row?.gender}</p>
                <p>Size: {row?.size}</p>
              </div>
            </div>
          </div>
        </Link>

        <td className={`${className}  lg:w-[240px]`}>{row?.auth_date}</td>
        <td className={className}>
          <div className="w-full lg:flex  justify-between pr-3">
            <p>{row?.condition}</p>

            {isInventory ? (
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  className={`small-font text-primary hidden  ${
                    isHover && "lg:flex"
                  } items-center gap-3`}
                  onClick={modalShowHandler}
                >
                  <Icons.Transfer />
                  <span>Transfer ownership</span>
                </button>
                <button
                  type="button"
                  className={`small-font text-primary hidden  ${
                    isHover && "lg:flex"
                  } items-center gap-3`}
                  onClick={e => CopyLink(row?.id)}
                >
                  <Icons.Copy />
                  <span> Copy link</span>
                </button>
              </div>
            ) : isProducts ? (
              <button
                type="button"
                className={`small-font text-primary hidden  ${
                  isHover && "lg:flex"
                } items-center gap-3`}
                onClick={e => CopyLink(row?.id)}
              >
                <Icons.Copy />
                <span> Copy link</span>
              </button>
            ) : (
              ""
            )}
          </div>
        </td>

        {/* for mobile  */}
        <td
          className={`${className} lg:hidden sticky top-0   right-0 !w-[40px] bg-white  `}
        >
          <div className="">
            <button
              type="button"
              className="cursor-pointer relative z-[1]"
              onClick={isInventory ? dropDownShowHandler : CopyLink(row?.id)}
              ref={targetEleDrop}
            >
              {isProducts && (
                <div  className="px-3">
                  <Icons.Copy />
                </div>
              )}
              {isInventory && Icons?.threeDots}
            </button>

            {/* button click dropdown popper  */}

            <Dropdown
              show={showDropDown}
              onHide={dropDownShowHandler}
              ignoreToCloseEleId={true}
              targetEle={targetEleDrop?.current}
              isBodyClickToClose
            >
              <TableSettingModal onTransfer={modalShowHandler} id={row?.id} />
            </Dropdown>
          </div>
        </td>
      </tr>

      <Modal
        show={showModal}
        onHide={modalShowHandler}
        title={"Transfer item ownership"}
        style={{ maxWidth: "368px", background: "#fff" }}
        id={"table-modal"}
      >
        <TransferOwner data={row} onHide={modalShowHandler} getMessage={getMessage} ></TransferOwner>
      </Modal>
    </>
  );
}

export default TableRow;
