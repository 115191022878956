import PropType from "prop-types";

function FilterButton({
  children,
  isIcon = true,
  onClick,
  className,
  isInnerHtmlOnMobile,
  ...props
}) {
  return (
    <button
      type="button"
      className={`customShadow  flex justify-center items-center  px-[12px] py-[16px] gap-[8px] rounded-sm  ${className} `}
      {...props}
      onClick={onClick}
    >
      {isIcon && (
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 11.4286C6 11.277 6.05268 11.1317 6.14645 11.0245C6.24021 10.9173 6.36739 10.8571 6.5 10.8571H9.5C9.63261 10.8571 9.75979 10.9173 9.85355 11.0245C9.94732 11.1317 10 11.277 10 11.4286C10 11.5801 9.94732 11.7255 9.85355 11.8326C9.75979 11.9398 9.63261 12 9.5 12H6.5C6.36739 12 6.24021 11.9398 6.14645 11.8326C6.05268 11.7255 6 11.5801 6 11.4286ZM4 8C4 7.84845 4.05268 7.7031 4.14645 7.59594C4.24021 7.48878 4.36739 7.42857 4.5 7.42857H11.5C11.6326 7.42857 11.7598 7.48878 11.8536 7.59594C11.9473 7.7031 12 7.84845 12 8C12 8.15155 11.9473 8.2969 11.8536 8.40406C11.7598 8.51122 11.6326 8.57143 11.5 8.57143H4.5C4.36739 8.57143 4.24021 8.51122 4.14645 8.40406C4.05268 8.2969 4 8.15155 4 8ZM2 4.57143C2 4.41988 2.05268 4.27453 2.14645 4.16737C2.24021 4.0602 2.36739 4 2.5 4H13.5C13.6326 4 13.7598 4.0602 13.8536 4.16737C13.9473 4.27453 14 4.41988 14 4.57143C14 4.72298 13.9473 4.86833 13.8536 4.97549C13.7598 5.08265 13.6326 5.14286 13.5 5.14286H2.5C2.36739 5.14286 2.24021 5.08265 2.14645 4.97549C2.05268 4.86833 2 4.72298 2 4.57143Z"
              fill="#020202"
            />
          </svg>
        </div>
      )}

      <div
        className={`small-font ${
          !isInnerHtmlOnMobile ? " block" : " sm:block hidden"
        }`}
      >
        {" "}
        {children ? children : ""}
      </div>
    </button>
  );
}

FilterButton.propTypes = {
  children: PropType.any,
  onClick: PropType.func,
  isIcon: PropType.bool,
  className: PropType.string,
  isInnerHtmlOnMobile: PropType.bool,
};

export default FilterButton;
