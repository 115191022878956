import { useEffect, useState } from "react";
import { Icons } from "../../../shared/lib/Icon";

function Pagination({
  len,
  perPage,
  maxEllipsis,
  currentPage = 1,
  currentPageHandler,
}) {
  const totalPages = Math.ceil(len / perPage); // Total number of pages
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [MaxEllipsis, setMaxEllipsis] = useState(maxEllipsis);

  useEffect(() => {
    const handleResize = () => {
      setMaxEllipsis(window.innerWidth <= 576 ? 1 : maxEllipsis);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [maxEllipsis]);

  const startEllipsis = Math.max(1, currentPage - MaxEllipsis);
  const endEllipsis = Math.min(pages.length, startEllipsis + MaxEllipsis + 1);

  const clickHandler = (e, num) => {
    if (num !== currentPage) {
      currentPageHandler(num);
    }
  };

  // Only render pagination if there are more than one page
  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex justify-end gap-[16px]">
      {/* Previous Button */}
      <button
        type="button"
        onClick={(e) => clickHandler(e, Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        {Icons?.leftArrow}
      </button>

      {/* Page Number Buttons */}
      <div className="flex items-center">
        {totalPages > MaxEllipsis && endEllipsis === pages.length && (
          <div className="flex items-center ">
            <button
              type="button"
              className={`w-[30px] h-[30px] sm:w-[46px] sm:h-[46px] rounded-full ${
                currentPage === 1 ? "bg-primary text-white" : ""
              } text-black flex justify-center items-center`}
              onClick={(e) => clickHandler(e, 1)}
            >
              {pages[0]}
            </button>
            <p className="mx-2 small-font">...</p>
          </div>
        )}

        {pages.slice(startEllipsis - 1, endEllipsis).map((num) => (
          <div key={num}>
            <button
              type="button"
              className={`w-[30px] h-[30px] sm:w-[46px] sm:h-[46px] rounded-full ${
                num === currentPage ? "bg-primary text-white" : ""
              } text-black flex justify-center items-center`}
              onClick={(e) => clickHandler(e, num)}
            >
              {num}
            </button>
          </div>
        ))}

        {totalPages > MaxEllipsis && endEllipsis < pages.length && (
          <div className="flex items-center ">
            <p className="mx-2 small-font">...</p>
            <button
              type="button"
              className={`w-[30px] h-[30px] sm:w-[46px] sm:h-[46px] rounded-full ${
                pages.length === currentPage ? "bg-primary text-white" : ""
              } text-black flex justify-center items-center`}
              onClick={(e) => clickHandler(e, pages.length)}
            >
              {pages[pages.length - 1]}
            </button>
          </div>
        )}
      </div>

      {/* Next Button */}
      <button
        type="button"
        onClick={(e) => clickHandler(e, Math.min(pages.length, currentPage + 1))}
        disabled={currentPage >= totalPages}
      >
        {Icons?.rightArrow}
      </button>
    </div>
  );
}

export default Pagination;
