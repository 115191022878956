/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "../../../shared/lib/Icon";

const Footer = () => {
  return (
    <div className="w-full py-6 bg-black text-white">
      <div className="customContainer">
        <div className="flex md:flex-row flex-col items-center justify-between gap-3">
          <div className="flex md:order-1 order-2 items-center gap-[24px]">
            <Link className="text-sm hover:underline" to="/terms_conditions">
              Terms and condition
            </Link>
            <Link className="text-sm hover:underline" to="/privacy_policy">
              Privacy policy
            </Link>
          </div>
          <div className="flex md:order-2 order-1 items-center md:gap-[16px] gap-6">
            <Link
              to="/"
              className="md:w-[24px] md:h-[24px] w-[32px] h-[32px] rounded-md bg-[#FDFDFD] text-black flex justify-center items-center text-lg md:text-[16px]"
            >
              {Icons.facebook}
            </Link>
            <Link
              to="/"
              className="md:w-[24px] md:h-[24px] w-[32px] h-[32px] rounded-md bg-[#FDFDFD] text-black flex justify-center items-center text-lg md:text-[16px]"
            >
              {Icons.instagram}
            </Link>
            <Link
              to="/"
              className="md:w-[24px] md:h-[24px] w-[32px] h-[32px] rounded-md bg-[#FDFDFD] text-black flex justify-center items-center text-lg md:text-[16px]"
            >
              {Icons.twitter}
            </Link>
          </div>
          <div className="text-white md:order-3 order-3 ">
            <p className="text-sm">© VRFY, 2023. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
