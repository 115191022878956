import style from "./Terms.module.css";
const Termscondition = () => {
  return (
    <div className="lg:py-[56px] py-[40px]">
      <h1 className="heading">Terms and conditions</h1>
      <div className="terms_contents customShadow rounded-md p-6 my-4 ">
        <p className={`${style.font_style} mt-4 text-base`}>
          <span className="block font-semibold">Clause 1:</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          diam quis felis egestas placerat sed nec eros. Praesent ultricies, leo
          eu vulputate congue, leo nisi viverra sapien, ac egestas sapien lorem
          id tortor. Sed tincidunt, metus eget mattis bibendum, purus diam
          porttitor massa, euismod facilisis arcu metus a ex. Mauris venenatis
          faucibus nulla in blandit. Mauris facilisis nec mauris eu rutrum.
          Donec est nibh, laoreet eget accumsan et, aliquet at tellus. Quisque
          nulla justo, egestas sed semper a, efficitur sit amet justo. Etiam a
          felis euismod purus cursus laoreet. Nam elit lorem, pulvinar quis
          massa at, ornare consequat augue. Pellentesque volutpat purus nec
          nulla bibendum aliquam. Proin tempus nec sem hendrerit porttitor.
          Nulla fringilla accumsan mauris vitae egestas. Aenean rutrum et neque
          at faucibus.
        </p>    
         <p className={`${style.font_style} mt-4 text-base`}>
          <span className="block font-semibold">Clause 1:</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          diam quis felis egestas placerat sed nec eros. Praesent ultricies, leo
          eu vulputate congue, leo nisi viverra sapien, ac egestas sapien lorem
          id tortor. Sed tincidunt, metus eget mattis bibendum, purus diam
          porttitor massa, euismod facilisis arcu metus a ex. Mauris venenatis
          faucibus nulla in blandit. Mauris facilisis nec mauris eu rutrum.
          Donec est nibh, laoreet eget accumsan et, aliquet at tellus. Quisque
          nulla justo, egestas sed semper a, efficitur sit amet justo. Etiam a
          felis euismod purus cursus laoreet. Nam elit lorem, pulvinar quis
          massa at, ornare consequat augue. Pellentesque volutpat purus nec
          nulla bibendum aliquam. Proin tempus nec sem hendrerit porttitor.
          Nulla fringilla accumsan mauris vitae egestas. Aenean rutrum et neque
          at faucibus.
        </p>    
         <p className={`${style.font_style} mt-4 text-base`}>
          <span className="block font-semibold">Clause 1:</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          diam quis felis egestas placerat sed nec eros. Praesent ultricies, leo
          eu vulputate congue, leo nisi viverra sapien, ac egestas sapien lorem
          id tortor. Sed tincidunt, metus eget mattis bibendum, purus diam
          porttitor massa, euismod facilisis arcu metus a ex. Mauris venenatis
          faucibus nulla in blandit. Mauris facilisis nec mauris eu rutrum.
          Donec est nibh, laoreet eget accumsan et, aliquet at tellus. Quisque
          nulla justo, egestas sed semper a, efficitur sit amet justo. Etiam a
          felis euismod purus cursus laoreet. Nam elit lorem, pulvinar quis
          massa at, ornare consequat augue. Pellentesque volutpat purus nec
          nulla bibendum aliquam. Proin tempus nec sem hendrerit porttitor.
          Nulla fringilla accumsan mauris vitae egestas. Aenean rutrum et neque
          at faucibus.
        </p>    
         <p className={`${style.font_style} mt-4 text-base`}>
          <span className="block font-semibold">Clause 1:</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          diam quis felis egestas placerat sed nec eros. Praesent ultricies, leo
          eu vulputate congue, leo nisi viverra sapien, ac egestas sapien lorem
          id tortor. Sed tincidunt, metus eget mattis bibendum, purus diam
          porttitor massa, euismod facilisis arcu metus a ex. Mauris venenatis
          faucibus nulla in blandit. Mauris facilisis nec mauris eu rutrum.
          Donec est nibh, laoreet eget accumsan et, aliquet at tellus. Quisque
          nulla justo, egestas sed semper a, efficitur sit amet justo. Etiam a
          felis euismod purus cursus laoreet. Nam elit lorem, pulvinar quis
          massa at, ornare consequat augue. Pellentesque volutpat purus nec
          nulla bibendum aliquam. Proin tempus nec sem hendrerit porttitor.
          Nulla fringilla accumsan mauris vitae egestas. Aenean rutrum et neque
          at faucibus.
        </p>    
         <p className={`${style.font_style} mt-4 text-base`}>
          <span className="block font-semibold">Clause 1:</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          diam quis felis egestas placerat sed nec eros. Praesent ultricies, leo
          eu vulputate congue, leo nisi viverra sapien, ac egestas sapien lorem
          id tortor. Sed tincidunt, metus eget mattis bibendum, purus diam
          porttitor massa, euismod facilisis arcu metus a ex. Mauris venenatis
          faucibus nulla in blandit. Mauris facilisis nec mauris eu rutrum.
          Donec est nibh, laoreet eget accumsan et, aliquet at tellus. Quisque
          nulla justo, egestas sed semper a, efficitur sit amet justo. Etiam a
          felis euismod purus cursus laoreet. Nam elit lorem, pulvinar quis
          massa at, ornare consequat augue. Pellentesque volutpat purus nec
          nulla bibendum aliquam. Proin tempus nec sem hendrerit porttitor.
          Nulla fringilla accumsan mauris vitae egestas. Aenean rutrum et neque
          at faucibus.
        </p>    
         <p className={`${style.font_style} mt-4 text-base`}>
          <span className="block font-semibold">Clause 1:</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          diam quis felis egestas placerat sed nec eros. Praesent ultricies, leo
          eu vulputate congue, leo nisi viverra sapien, ac egestas sapien lorem
          id tortor. Sed tincidunt, metus eget mattis bibendum, purus diam
          porttitor massa, euismod facilisis arcu metus a ex. Mauris venenatis
          faucibus nulla in blandit. Mauris facilisis nec mauris eu rutrum.
          Donec est nibh, laoreet eget accumsan et, aliquet at tellus. Quisque
          nulla justo, egestas sed semper a, efficitur sit amet justo. Etiam a
          felis euismod purus cursus laoreet. Nam elit lorem, pulvinar quis
          massa at, ornare consequat augue. Pellentesque volutpat purus nec
          nulla bibendum aliquam. Proin tempus nec sem hendrerit porttitor.
          Nulla fringilla accumsan mauris vitae egestas. Aenean rutrum et neque
          at faucibus.
        </p>    
        
      </div>
    </div>
  );
};

export default Termscondition;
