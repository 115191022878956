import { useState } from "react";

function SearchBar({ onChange }) {
  const [keyword, setKeyword] = useState("");
  const onChangeHandler = (e) => {
    if (typeof onChange === "function") {
      onChange(e.target.value);
    }
    onChange(e.target.value);
    setKeyword(e.target.value);
  };

  return (
    <form className="md:max-w-[350px] w-auto px-3 customShadow rounded-sm" style={{ borderRadius: '.25rem' }}>
      <div className=" flex justify-between   items-center ">
        <input
          type="search"
          name="search"
          placeholder="Search shoe name"
          className="w-full py-[1rem] bg-transparent  focus:outline-none"
          value={keyword}
          onChange={onChangeHandler}
        />
        {/* search icon   */}
        <div>
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10.1818"
              cy="10.1818"
              r="8.1818"
              stroke="#020202"
              strokeWidth="1.5"
            />
            <path
              d="M21.9999 22L16.0909 16.0909"
              stroke="#020202"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </form>
  );
}

export default SearchBar;
