import { useState } from "react";
import { logo } from '../../shared/images';


import Login from "./Login";
import SignUp from "./SignUp";
const Auth = () => {
  const [activeTab, setActiveTab] = useState("login"); // Default to 'login'

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="flex justify-center items-center my-6">
        <img className="sm:h-[32px] h-[24px]" src={logo} alt="Brand" />
      </div>

      
      <section className="w-full h-full flex flex-col justify-center  items-center">
        <div className="customContainer">
          <div className="flex flex-col w-full sm:gap-[80px] gap-[40px] justify-center">
            {/* login & signup body */}
            <div className="sm:max-w-[362px] w-full mx-auto  shadow p-6 rounded-md bg-white border border-[#F3F3F3]">
              <div className="flex w-full text-custom-gray">
                <button
                  className={`text-[16px] px-6 pb-3 w-full ${
                    activeTab === "login"
                      ? "border-b-2 border-primary text-primary font-semibold"
                      : ""
                  }`}
                  onClick={() => handleTabClick("login")}
                >
                  Login
                </button>
                <button
                  className={`text-[16px] px-6 pb-3 w-full ${
                    activeTab === `signup`
                      ? "border-b-2 border-primary text-primary font-semibold"
                      : ""
                  }`}
                  onClick={() => handleTabClick(`signup`)}
                >
                  Sign Up
                </button>
              </div>
              <div className="mt-6 w-full">
                {activeTab === "login" ? (
                  <div className="flex flex-col">
                    <Login />
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <SignUp />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Auth;
